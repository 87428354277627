import React from "react";
import { Link } from "react-router-dom";

export default function ChemistrySidebar(props) {
  React.useEffect(() => {
    props.navigationset("games");
  }, []);

  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
  };

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Himiýa</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "math_choose");
            }}
            to="/chemistry/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math_choose" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Sapak Saýla</h5>
              <p>Başlamak üçin sapak saýlaň</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry_level1");
            }}
            to="/chemistry/level/1"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry_level1" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 1</h5>
              <p>1-dan 15-a çenli himiki elementler</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry_level2");
            }}
            to="/chemistry/level/2"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry_level2" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 2</h5>
              <p>15-den 30-a çenli himiki elementler</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry_level3");
            }}
            to="/chemistry/level/3"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry_level3" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 3</h5>
              <p>30-dan 45-e çenli himiki elementler</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry_level4");
            }}
            to="/chemistry/level/4"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry_level4" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 4</h5>
              <p>45-den 60-a çenli himiki elementler</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry_level5");
            }}
            to="/chemistry/level/5"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry_level5" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 5</h5>
              <p>60-dan 75-e çenli himiki elementler</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry_level6");
            }}
            to="/chemistry/level/6"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry_level6" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 6</h5>
              <p>75-den 90-a çenli himiki elementler</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry_level7");
            }}
            to="/chemistry/level/7"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry_level7" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 7</h5>
              <p>90-dan 105-e çenli himiki elementler</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry_level8");
            }}
            to="/chemistry/level/8"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry_level8" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 8</h5>
              <p>105-den 119-a çenli himiki elementler</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
