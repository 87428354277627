import React from "react";
import MessageCard from "../cards/MessageCard";

const slugify = (text) => {
  return (
    text
      .toString()
      // .normalize("NFKD")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "")
      .replace(/[.!?,]/g, "")
      .replace(/\-\-+/g, "")
      .replace(/;/g, "")
      .replace(/'/g, "")
      .replace(/"/g, "")
      .replace(/,/g, "")
  );
};

export default function LearnWord(props) {
  const [dialog, setDialog] = React.useState([{}]);

  const [isTyping, setIsTyping] = React.useState(false);

  const [message, setMessage] = React.useState("");
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const questions = React.useRef(false);
  // const [correctAnswer, setCorrectAnswer] = React.useState({});
  const correctAnswer = React.useRef(false);
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer
  const interval = React.useRef(false);
  const input = React.useRef();
  const welcomeMessage = React.useRef("");
  const audioRef = React.useRef();

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (
    text,
    additional1,
    additional2,
    additional3,
    audio = null
  ) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        audio: audio,
        message: text,
        additional1: additional1,
        additional2: additional2,
        additional3: additional3,
        me: false,
        info: true,
      },
    ]);
    if (audio) {
      audioRef.current.src = "https://peydalan.com.tm" + audio;
      audioRef.current.play();
    }
  };

  const sendMessage = (e) => {
    var flag;
    if (props.lesson.category === "english") {
      flag = "🇺🇸 ";
    } else if (props.lesson.category === "russian") {
      flag = "🇷🇺 ";
    } else if (props.lesson.category === "chinese") {
      flag = "🇨🇳";
    }

    if (message && message !== "\n") {
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);
      if (
        slugify(message.toLowerCase()) ===
          slugify(correctAnswer.current.another_lang.toLowerCase()) ||
        message.replaceAll(" ", "").replaceAll("\n", "") ===
          correctAnswer.current.additional1.replaceAll(" ", "")
      ) {
        setIsTyping(true);
        setTimeout(() => {
          sendInfo(
            correctAnswer.current.turkmen,
            "",
            "",
            "",
            correctAnswer.current.audio_turkmen
          );
        }, 1000);

        setTimeout(() => {
          if (correctAnswer.current.additional3) {
            sendInfo(
              correctAnswer.current.additional2 +
                "\n" +
                correctAnswer.current.additional3 +
                "\n" +
                correctAnswer.current.description
            );
          } else {
            sendInfo(
              flag +
                correctAnswer.current.description +
                "\n🇹🇲 " +
                correctAnswer.current.additional1
            );
          }
        }, 2500);

        setTimeout(() => {
          questions.current = questions.current.filter(
            (q, i) => q.id !== correctAnswer.current.id
          ); // remove the question from the questions array
        }, 2000);
        setTimeout(() => {
          sendQuestion();
        }, 4000); // send another question
      } else {
        setTimeout(() => {
          if (correctAnswer.current.additional3) {
            sendError(
              correctAnswer.current.another_lang +
                "\n" +
                correctAnswer.current.additional1
            );
          } else {
            sendError(correctAnswer.current.another_lang);
          }
        }, 1000);
      }
    }
    input.current.focus();
    setMessage("");
  };

  const sendQuestion = () => {
    if (questions.current.length > 0) {
      if (interval.current) {
        clearInterval(interval.current);
      }
      // check if there are any questions left
      const index = Math.floor(Math.random() * questions.current.length); // get a random index
      const question = questions.current[index];
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          audio: question.audio_another,
          additional1: question.additional1,
          additional2: question.additional2,
          additional3: question.additional3,
          message: question.another_lang,
          me: false,
        },
      ]);
      correctAnswer.current = question; // add the question to the dialog
      if (question.audio_another) {
        audioRef.current.src =
          "https://peydalan.com.tm" + question.audio_another;
        var playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {})
            .catch((e) => {
              console.error(e);
            });
        }
      }
    } else {
      if (!interval.current) {
        interval.current = setInterval(() => {
          sendQuestion();
        }, 1000);
      }
    }
  };

  React.useEffect(() => {
    if (questions.current.length === 0) {
      if (Object.keys(correctAnswer.current).length !== 0) {
        setTimeout(() => {
          sendInfo("Berekella, indiki aylawymyzy dowam edelin");
        }, 1000);
        setTimeout(() => {
          questions.current = props.words;
          sendQuestion();
        }, 2000);
        return;
      }
    }
  }, [questions.current]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (!props.ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: props.ad.image,
          me: false,
          ad: true,
          view: props.ad.view,
          id: props.ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [props.ad]);

  React.useEffect(() => {
    if (props.lesson) {
      var language = "";
      if (props.lesson.category === "english") {
        language = "Iňlis dili";
      } else if (props.lesson.category === "russian") {
        language = "Rus dili";
      } else if (props.lesson.category === "chinese") {
        language = "Hytaý dili";
      }
      welcomeMessage.current =
        "Salam! " +
        language +
        " öwrenmegiň 1-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulymyz meniň size ýazyp ugradan sözlerimi siz dogry göçürip yzyna ugratmaly, soňra men siziň ýazgyňyzy barlap, şol sözüň terjimesini sesli ugradaryn hemde bir sözlem içinde getirip bererin. Öwrenmegiň bu usuly: sözleri dogry ýazmaklygyňyza we sözleriň ýadyňyzda galmaklygyna has hem täsirli bolar. Good Luck!";
    }
  }, [props.lesson]);

  React.useEffect(() => {
    if (props.words.length !== 0) {
      questions.current = props.words;
      // Set the state with the data
    }
  }, [props.words]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio hidden ref={audioRef} />
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <MessageCard
                key={index}
                audioRef={audioRef}
                ad={item.ad}
                view={item.view}
                setInputFocus={setInputFocus}
                sponsorId={item.id}
                message={item.message}
                additional1={item.additional1}
                additional2={item.additional2}
                additional3={item.additional3}
                audio={item.audio}
                me={item.me && "true"}
                error={item.error && "true"}
                info={item.info && "true"}
              />
            ))}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                onChange={handleChange}
                rows="0"
              ></textarea>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  sendMessage();
                }}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
