import React from "react";
import { Link } from "react-router-dom";

export default function GameSidebar(props) {
  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
    // setTimeout(() => {}, 1000);
  };

  React.useEffect(() => {
    props.navigationset("games");
  }, []);

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Okuw Oýunlary</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "games");
            }}
            to="/games"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "login" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Okuw Oýunlary</h5>
              <p>Oýun saýla</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "geography");
            }}
            to="/geography"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "geography" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Geografiýa</h5>
              <p>Geografiýa </p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "math");
            }}
            to="/math"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Matematika</h5>
              <p>Matematika</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chemistry");
            }}
            to="/chemistry"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chemistry" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Himiýa</h5>
              <p>Himiýa</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "blitz");
            }}
            to="/blitz"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "blitz" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Yzygider hasap</h5>
              <p>Yzygider hasap</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "operation");
            }}
            to="/operation"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "operation" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Mysalyň amallaryny tapmak</h5>
              <p>Mysalyň amallaryny tapmak</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "alphabet");
            }}
            to="/alphabet"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "alphabet" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Harplyk</h5>
              <p>Harplyk</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "karaoke");
            }}
            to="/alphabet"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "karaoke" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Karaoke</h5>
              <p>Karaoke</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
