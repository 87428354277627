import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function MarketSidebar(props) {
  const [canSeeFeedback, setCanSeeFeedback] = React.useState(false);
  React.useEffect(() => {
    props.navigationset("market");
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      const headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
      axios
        .get("https://peydalan.com.tm/api/market/check", { headers: headers })
        .then((response) => {
          // set the array state to the response data
          if (response.data.reg === "market") {
            localStorage.setItem("market", response.data.code);
            localStorage.removeItem("service");
          } else {
            localStorage.setItem("service", response.data.code);
            localStorage.removeItem("market");
          }
        })
        .catch((error) => {
          // handle error
          localStorage.removeItem("market");
          localStorage.removeItem("service");
        });

      axios
        .get("https://peydalan.com.tm/api/get-user", { headers: headers })
        .then((response) => {
          // set the array state to the response data
          if (response.data.can_see_feedback) {
            setCanSeeFeedback(true);
          }
        })
        .catch((error) => {});
    }
  }, []);

  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
  };

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Market Bölümleri</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "products");
            }}
            to="/market"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "products" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Harytlar</h5>
              <p>Hemme harytar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "services");
            }}
            to="/market/services"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "services" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Hyzmatlar</h5>
              <p>Hemme hyzmatlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "markets");
            }}
            to="/market/all"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "markets" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Marketler</h5>
              <p>Hemme marketler</p>
            </div>
          </Link>
          {!localStorage.getItem("service") ? (
            <Link
              onClick={(e) => {
                handleClick(e, "my-products");
              }}
              to="/market/mine"
              className={
                "filterDiscussions  single all unread " +
                (props.menu === "my-products" && "active") +
                (!localStorage.getItem("market") && " disabled-link")
              }
              id="list-chat-list"
              data-toggle="list"
              role="tab"
            >
              <div className="data">
                <h5>Harytlarym</h5>
                <p>Meniň harytlarym</p>
              </div>
            </Link>
          ) : (
            <Link
              onClick={(e) => {
                handleClick(e, "my-services");
              }}
              to="/market/mine/services"
              className={
                "filterDiscussions  single all unread " +
                (props.menu === "my-services" && "active") +
                (!localStorage.getItem("service") && " disabled-link")
              }
              id="list-chat-list"
              data-toggle="list"
              role="tab"
            >
              <div className="data">
                <h5>Hyzmatlarym</h5>
                <p>Meniň hyzmatlarym</p>
              </div>
            </Link>
          )}
          {canSeeFeedback && (
            <Link
              onClick={(e) => {
                handleClick(e, "feedbacks");
              }}
              to="/market/feedbacks"
              className={
                "filterDiscussions  single all unread " +
                (props.menu === "feedbaçks" && "active")
              }
              id="list-chat-list"
              data-toggle="list"
              role="tab"
            >
              <div className="data">
                <h5>Şykaýatlar</h5>
                <p>Şykaýatlar</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
