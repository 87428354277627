import React from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import { buildStyles } from "react-circular-progressbar";

export function OperationCard(props) {
  const navigate = useNavigate();
  const counter = React.useRef(0);
  const [currentStep, setCurrentStep] = React.useState();
  const selected = React.useRef();
  const interval = React.useRef();

  const Stepper = (totalSteps, stepCallback, onCompleteCallback) => {
    interval.current = setInterval(() => {
      const newStep = counter.current + 1;

      // Call the step callback function for every given step
      if (newStep % stepCallback.every === 0) {
        stepCallback.callback(newStep);
      }

      // If all steps are completed, call the complete callback function and stop the interval
      if (newStep >= totalSteps) {
        clearInterval(interval);
        counter.current = 0;
        setTimeout(() => {
          setCurrentStep(0);
        }, 100);
        setTimeout(() => {
          onCompleteCallback();
        }, 150);
      }

      counter.current = newStep;
    }, stepCallback.interval);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(interval.current);
  };

  const stepCallback = {
    every: 1,
    interval: props.delayInSeconds * 10, // in milliseconds
    callback: (step) => {
      if (!selected.current) {
        setCurrentStep(step);
      }
      // Call your specific function here for every given step
    },
  };

  const completedCallback = () => {
    if (!selected.current && props.question) {
      selected.current = true;
      props.setSelected("not-selected");
    }
  };

  React.useEffect(() => {
    if (props.question && !props.first) {
      Stepper(100, stepCallback, completedCallback);
    }
  }, []);

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  const navigateToSponsor = (e, id) => {
    navigate("/sponsors/major/" + id);
  };

  // React.useEffect(() => {
  //   if (!mountedRef) {
  //     if (props.audio) {
  //       audioRef.current.play().catch((error) => {
  //         console.error(error);
  //       });
  //     }
  //     mountedRef.current = true;
  //   }
  // }, []);

  return (
    <div className={props.me ? "message me" : "message"}>
      <div className="text-main">
        <div className={props.me ? "text-group me" : "text-group"}>
          <div className={classes}>
            {props.ad ? (
              <div>
                <img
                  onClick={(e) => {
                    navigateToSponsor(e, props.sponsorId);
                  }}
                  src={props.message}
                />
                <div>
                  <i className="material-icons" id="ad-view-i">
                    visibility
                  </i>{" "}
                  {props.view}
                </div>
              </div>
            ) : (
              <div>
                <div>
                  {props.question ? (
                    <div>
                      <CircularProgressbar
                        value={currentStep}
                        text={selected.current ? props.answer : props.message}
                        styles={buildStyles({
                          pathTransition: "none",
                          textSize: "15px",
                        })}
                      />
                      <div className="operation-buttons">
                        <button
                          onClick={() => {
                            props.setSelected("+");
                            selected.current = true;
                            clearInterval(interval.current);
                          }}
                          disabled={selected.current}
                        >
                          +
                        </button>
                        <button
                          onClick={() => {
                            props.setSelected("-");
                            selected.current = true;
                            clearInterval(interval.current);
                          }}
                          disabled={selected.current}
                        >
                          -
                        </button>
                        <button
                          onClick={() => {
                            props.setSelected("*");
                            selected.current = true;
                            clearInterval(interval.current);
                          }}
                          disabled={selected.current}
                        >
                          *
                        </button>
                        <button
                          onClick={() => {
                            props.setSelected("/");
                            selected.current = true;
                            clearInterval(interval.current);
                          }}
                          disabled={selected.current}
                        >
                          /
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {props.message} <br />
                      {props.button && (
                        <button
                          onClick={() => {
                            props.callback();
                          }}
                          className="operation-next-button"
                        >
                          Indiki
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function OperationTestCard(props) {
  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  return (
    <div className={props.me ? "message me" : "message"}>
      <div className="text-main">
        <div className={props.me ? "text-group me" : "text-group"}>
          <div className={classes}>
            <div>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(1);
                }}
                disabled={props.selected}
              >
                1 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(2);
                }}
                disabled={props.selected}
              >
                2 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(3);
                }}
                disabled={props.selected}
              >
                3 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(4);
                }}
                disabled={props.selected}
              >
                4 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(5);
                }}
                disabled={props.selected}
              >
                5 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(6);
                }}
                disabled={props.selected}
              >
                6 sekunt
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
