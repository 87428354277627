import React from "react";
import axios from "axios";
import BlitzCard, { BlitzTestCard } from "../cards/BlitzCard";
import { useNavigate } from "react-router-dom";

export function Blitz(props) {
  const [dialog, setDialog] = React.useState([{}]);

  const [isTyping, setIsTyping] = React.useState(false);

  const [ad, setAd] = React.useState();
  const [message, setMessage] = React.useState("");
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer

  const counter = React.useRef(0);
  const [total, setTotal] = React.useState(0);
  const statistics = React.useRef({ corrects: 0, errors: 0 });
  const [timeSelected, setTimeSelected] = React.useState(
    localStorage.getItem("blitz")
  );

  const input = React.useRef();
  const audioObject = React.useRef();
  const audioObject2 = React.useRef();
  const welcomeMessage = React.useRef("");

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };
  const playCorrectSound = () => {
    audioObject.current.play();
  };

  const playWrongSound = () => {
    audioObject2.current.play();
  };
  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (message) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: message,
        me: false,
        info: true,
      },
    ]);
  };

  const sendMessage = (e) => {
    if (message && message !== "\n") {
      counter.current = counter.current + 1;
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);

      var answer = message.replace("\n", "").replace(" ", "");

      if (answer == total.toString()) {
        setIsTyping(true);
        statistics.current = {
          ...statistics.current,
          corrects: statistics.current.corrects + 1,
        };
        setTimeout(() => {
          sendInfo("👍 Dogry.");
          playCorrectSound();
        }, 1000);

        setTimeout(() => {
          sendQuestion();
        }, 2000); // send another question
      } else {
        setTimeout(() => {
          statistics.current = {
            ...statistics.current,
            errors: statistics.current.errors + 1,
          };
          sendError(total);
          playWrongSound();
        }, 1000);
        setTimeout(() => {
          sendQuestion();
        }, 2000);
      }
      if (counter.current == 20) {
        counter.current = 0;
        sendInfo(
          "20 mysaldan \n Dogrysy: " +
            statistics.current.corrects +
            " \n Ýalňyşy: " +
            statistics.current.errors
        );
        setTimeout(() => {}, 1000);
      }
    }
    input.current.focus();
    setMessage("");
  };

  const sendQuestion = () => {
    setTotal(0);
    setIsTyping(false);
    // get the question at that index
    setDialog((dialogs) => [
      ...dialogs,
      {
        question: "true",
        me: false,
      },
    ]);
  };

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });

    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (ad) {
      setTimeout(() => {
        setDialog((dialog) => [
          {
            message: ad.image,
            me: false,
            ad: true,
            view: ad.view,
            id: ad.id,
          },
          ...dialog.slice(1),
        ]);
      }, 500);
    }
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio
            ref={audioObject}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/correct_answer.mp3"
          ></audio>
          <audio
            ref={audioObject2}
            hidden
            src="https://peydalan.com.tm/api/media/system_sounds/wrong_answer.mp3"
          ></audio>
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              !item.selectTime ? (
                <BlitzCard
                  key={index}
                  ad={item.ad}
                  view={item.view}
                  setInputFocus={setInputFocus}
                  sponsorId={item.id}
                  question={item.question}
                  message={item.message}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                  setTotal={setTotal}
                  min={props.min}
                  max={props.max}
                  delayInSeconds={timeSelected}
                />
              ) : (
                <BlitzTestCard
                  key={index}
                  callback={item.callback}
                  selected={timeSelected}
                />
              )
            )}
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef}></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                onChange={handleChange}
                disabled={total === 0 ? true : false}
                rows="0"
              ></textarea>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  sendMessage();
                }}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChooseBlitzTimeout() {
  const navigate = useNavigate();
  const handleClick = (button_name) => {
    localStorage.setItem("blitz", button_name);
    navigate("/blitz/level/1");
  };

  return (
    <div className="test-frame lesson-frame">
      <h4 className="lesson-title">Yzygider Hasap</h4>
      <button
        className="test-button"
        onClick={() => {
          handleClick("1");
        }}
      >
        1 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("2");
        }}
      >
        2 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("3");
        }}
      >
        3 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("4");
        }}
      >
        4 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("5");
        }}
      >
        5 sekunt
      </button>
      <button
        className="test-button"
        onClick={() => {
          handleClick("6");
        }}
      >
        6 sekunt
      </button>
      <br />
      <h5>Düşündiriş teksti</h5>
      <p></p>
    </div>
  );
}
