import React, { useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate, useParams } from "react-router-dom";
import { CircleLoader } from "react-spinners";

export function SponsorAdminCard(props) {
  const [opened, setOpened] = React.useState(false);
  const [current, setCurrent] = React.useState(1);
  const navigate = useNavigate();

  const open = () => {
    setOpened(!opened);
  };

  const jump = (index) => {
    setCurrent(index);
  };

  const change = (value) => {
    var number = value ? 1 : -1;
    current + number > 3
      ? jump(1)
      : current + number < 1
      ? jump(3)
      : setCurrent(current + number);
  };

  const editButton = () => {
    navigate("/sponsors/admin/edit/" + props.item.id);
  };

  const deleteButton = () => {
    const headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
    const data = { notice_id: props.item.id };
    axios
      .delete("https://peydalan.com.tm/api/notice/admin", {
        headers: headers,
        data: data,
      })
      .then((response) => {
        props.refresh();
      });
  };

  return (
    <div className="text-content-frame">
      <div>
        {!opened && <img src={props.item.image} alt="" />}
        <div style={{ display: opened ? "block" : "none" }}>
          <div className="slideshow-container">
            {current == 1 && (
              <div>
                <div className="numbertext">1 / 3</div>
                <img src={props.item.image} className="slide-img" alt="" />
              </div>
            )}
            {current == 2 && (
              <div>
                <div className="numbertext">2 / 3</div>
                <img
                  src={props.item.image2 ? props.item.image2 : props.item.image}
                  className="img"
                  alt=""
                />
              </div>
            )}
            {current == 3 && (
              <div>
                <div className="numbertext">3 / 3</div>
                <img
                  src={props.item.image3 ? props.item.image3 : props.item.image}
                  className="img"
                  alt=""
                />
              </div>
            )}
            <a
              className="prev"
              onClick={() => {
                change(false);
              }}
            >
              &#10094;
            </a>
            <a
              className="next"
              onClick={() => {
                change(true);
              }}
            >
              &#10095;
            </a>
          </div>
        </div>
      </div>
      {props.item.title && (
        <h5
          style={{ marginTop: "10px" }}
          className={opened ? "" : "margin-bottom-px"}
        >
          {props.item.title}
        </h5>
      )}
      {opened && <p>{props.item.body}</p>}

      {opened && (
        <div className="product-market-info">
          <a href={props.item.link}>{props.item.link}</a>
        </div>
      )}
      <button
        className="text-button"
        onClick={() => {
          open();
        }}
      >
        {!opened ? "Doly görkez" : "Gizle"}
      </button>
      <button className="text-button" onClick={editButton}>
        Edit
      </button>
      <button className="text-button" onClick={deleteButton}>
        Delete
      </button>
    </div>
  );
}

export function SponsorAddForm(props) {
  const [image1, setImage1] = React.useState();
  const [image2, setImage2] = React.useState();
  const [image3, setImage3] = React.useState();
  const [preview1, setPreview1] = React.useState();
  const [preview2, setPreview2] = React.useState();
  const [preview3, setPreview3] = React.useState();
  const [body, setBody] = React.useState();
  const [title, setTitle] = React.useState();
  const [link, setLink] = React.useState();
  const [category, setCategory] = React.useState("profilim");
  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState();
  const [errors, setErrors] = React.useState();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [popupOpened, setPopupOpened] = React.useState(false);

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/get-notice-categories")
      .then((response) => {
        console.log(response.data);
        setCategories(response.data);
      })
      .catch((error) => {});
  }, []);

  const onFileChange1 = (event) => {
    // Update the state
    setImage1(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview1(objectUrl);
  };

  const onFileChange2 = (event) => {
    // Update the state
    setImage2(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview2(objectUrl);
  };

  const onFileChange3 = (event) => {
    // Update the state
    setImage3(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview3(objectUrl);
  };

  const handleBodyChange = (event) => {
    setBody(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleProfilimCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const addSponsor = () => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    const formData = new FormData();
    formData.append("image", image1);
    formData.append("image2", image2);
    formData.append("image3", image3);
    formData.append("body", body);
    formData.append("title", title);
    formData.append("link", link);
    formData.append("category", category);
    formData.append("profilim_category", selectedCategory);

    setLoading(true);

    axios
      .post("https://peydalan.com.tm/api/notice/admin", formData, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        navigate("/sponsors/admin");
      })
      .catch((error) => {
        if (error.response.status == 403) {
          setLoading(false);
          setErrors(error.response.data);
        } else {
          setLoading(false);
          setPopupOpened(true);
        }
      });
  };

  return (
    <div>
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">Bir zatlar yalnysh gitdi</div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                OK
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      {loading ? (
        <CircleLoader className="loader" color="#33A2F4" size={90} />
      ) : (
        <div className="admin-form">
          <div
            className={
              "admin-form-element " +
              (errors && errors.image && "admin-form-element-error")
            }
          >
            {preview1 && <img src={preview1} alt="" />}
            <input type="file" onChange={onFileChange1} />
            <span className="admin-form-error-span">
              {errors && errors.image}
            </span>
          </div>
          <div
            className={
              "admin-form-element " +
              (errors && errors.image2 && "admin-form-element-error")
            }
          >
            {preview2 && <img src={preview2} alt="" />}
            <input type="file" onChange={onFileChange2} />
            <span className="admin-form-error-span">
              {errors && errors.image2}
            </span>
          </div>
          <div
            className={
              "admin-form-element " +
              (errors && errors.image3 && "admin-form-element-error")
            }
          >
            {preview3 && <img src={preview3} alt="" />}
            <input type="file" onChange={onFileChange3} />
            <span className="admin-form-error-span">
              {errors && errors.image3}
            </span>
          </div>

          <div
            className={
              "admin-form-div " +
              (errors && errors.title && "admin-form-element-error")
            }
          >
            <span>Tekstin Ady:</span>
            <input onChange={handleTitleChange} />
          </div>
          <div
            className={
              "admin-form-div " +
              (errors && errors.body && "admin-form-element-error")
            }
          >
            <span>Teksti:</span>
            <textarea onChange={handleBodyChange} rows={10}></textarea>
            <span className="admin-form-error-span">
              {errors && errors.code}
            </span>
          </div>
          <div
            className={
              "admin-form-div " +
              (errors && errors.link && "admin-form-element-error")
            }
          >
            <span>Link:</span>
            <input onChange={handleLinkChange} />
            <span className="admin-form-error-span">
              {errors && errors.category}
            </span>
          </div>
          <div
            className={
              "admin-form-div " +
              (errors && errors.category && "admin-form-element-error")
            }
          >
            <select onChange={handleCategoryChange}>
              <option value={"profilim"}>Profilim</option>
              <option value={"big-sponsor"}>Hemaýatçylar</option>
              <option value={"ads"}>Mahabatlar</option>
            </select>

            <span className="admin-form-error-span">
              {errors && errors.category}
            </span>
          </div>
          <div
            className={
              "admin-form-div " +
              (errors && errors.profilim_category && "admin-form-element-error")
            }
          >
            <select onChange={handleProfilimCategoryChange}>
              <option value={""}>Saylanmadyk</option>
              {categories.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>

            <span className="admin-form-error-span">
              {errors && errors.profilim_category}
            </span>
          </div>
          <button className="admin-form-button" onClick={addSponsor}>
            Add
          </button>
        </div>
      )}
    </div>
  );
}

export function SponsorEditForm(props) {
  const { id } = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [image1, setImage1] = React.useState();
  const [image2, setImage2] = React.useState();
  const [image3, setImage3] = React.useState();
  const [preview1, setPreview1] = React.useState();
  const [preview2, setPreview2] = React.useState();
  const [preview3, setPreview3] = React.useState();
  const [categories, setCategories] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/get-notice-categories")
      .then((response) => {
        console.log(response.data);
        setCategories(response.data);
      })
      .catch((error) => {});
    const headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
    axios
      .get("https://peydalan.com.tm/api/notice/check?id=" + id, {
        headers: headers,
      })
      .then((response) => {
        setData(response.data);
        setPreview1(response.data.image);
        setPreview2(response.data.image2);
        setPreview3(response.data.image3);
      })
      .catch((error) => {
        navigate("/market/mine");
      });
  }, []);

  const onFileChange1 = (event) => {
    // Update the state
    setImage1(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview1(objectUrl);
  };

  const onFileChange2 = (event) => {
    // Update the state
    setImage2(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview2(objectUrl);
  };

  const onFileChange3 = (event) => {
    // Update the state
    setImage3(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview3(objectUrl);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (event.target.name == "code") {
      if (event.target.value < 100 && event.target.value >= 0) {
        setData({ ...data, [name]: value });
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const saveProduct = () => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    const formData = new FormData();
    formData.append("image", image1);
    formData.append("image2", image2);
    formData.append("image3", image3);
    formData.append("id", data.id);
    formData.append("title", data.title);
    formData.append("body", data.body);
    formData.append("link", data.link);
    formData.append("category", data.category);
    formData.append("profilim_category", data.profilim_category);

    axios
      .patch("https://peydalan.com.tm/api/notice/admin", formData, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        navigate("/sponsors/admin");
      });
  };

  return (
    <div className="admin-form">
      {data && (
        <div>
          <div className="admin-form-element">
            {preview1 && <img src={preview1} alt="" />}
            <input type="file" onChange={onFileChange1} />
          </div>
          <div className="admin-form-element">
            {preview2 && <img src={preview2} alt="" />}
            <input type="file" onChange={onFileChange2} />
          </div>
          <div className="admin-form-element">
            {preview3 && <img src={preview3} alt="" />}
            <input type="file" onChange={onFileChange3} />
          </div>
          <div className="admin-form-div">
            <span>Tekstin Ady:</span>
            <input
              name="title"
              onChange={handleFormChange}
              value={data.title}
            />
          </div>
          <div className="admin-form-div">
            <span>Teksti:</span>
            <textarea
              name="body"
              onChange={handleFormChange}
              value={data.body}
              rows={10}
            ></textarea>
          </div>
          <div className="admin-form-div">
            <span>Link:</span>
            <input name="link" onChange={handleFormChange} value={data.link} />
          </div>
          <div
            className={
              "admin-form-div "
              // (errors && errors.category && "admin-form-element-error")
            }
          >
            <select
              onChange={handleFormChange}
              defaultValue={data.category}
              name="category"
            >
              <option value={"profilim"}>Profilim</option>
              <option value={"big-sponsor"}>Hemaýatçylar</option>
              <option value={"ads"}>Mahabatlar</option>
            </select>

            {/* <span className="admin-form-error-span">
              {errors && errors.category}
            </span> */}
          </div>
          <div
            className={
              "admin-form-div "
              // (errors && errors.category && "admin-form-element-error")
            }
          >
            <select
              onChange={handleFormChange}
              defaultValue={
                data.profilim_category ? data.profilim_category.id : ""
              }
              name="profilim_category"
            >
              <option value={""}>Saylanmadyk</option>
              {categories.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
            </select>

            {/* <span className="admin-form-error-span">
              {errors && errors.category}
            </span> */}
          </div>
          <button className="admin-form-button" onClick={saveProduct}>
            Save
          </button>
        </div>
      )}
    </div>
  );
}
