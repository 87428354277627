import React from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import { buildStyles } from "react-circular-progressbar";

const getRandomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const getRandomOperation = () => {
  const operations = ["+", "-"];
  const randomIndex = Math.floor(Math.random() * operations.length);
  return operations[randomIndex];
};

export default function BlitzCard(props) {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [question, setQuestion] = React.useState(
    getRandomNumber(props.min, props.max)
  );
  const navigate = useNavigate();
  const counter = React.useRef(0);
  const total = React.useRef(question);
  const questionCounter = React.useRef(1);
  const [started, setStarted] = React.useState(false);

  const Stepper = (totalSteps, stepCallback, onCompleteCallback) => {
    const interval = setInterval(() => {
      const newStep = counter.current + 1;

      // Call the step callback function for every given step
      if (newStep % stepCallback.every === 0) {
        stepCallback.callback(newStep);
      }

      // If all steps are completed, call the complete callback function and stop the interval
      if (newStep >= totalSteps) {
        clearInterval(interval);
        counter.current = 0;
        setTimeout(() => {
          setCurrentStep(0);
        }, 100);
        setTimeout(() => {
          onCompleteCallback();
        }, 150);
      }

      counter.current = newStep;
    }, stepCallback.interval);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(interval);
  };

  const changeQuestion = () => {
    let number, operation, newTotal;

    if (questionCounter.current >= 8) {
      setQuestion("=?");
      props.setTotal(total.current);
      return;
    }

    do {
      // Generate a new number and operation
      number = getRandomNumber(props.min, props.max);
      operation = getRandomOperation();

      // Perform the calculation based on the random operation
      switch (operation) {
        case "+":
          newTotal = total.current + number;
          break;
        case "-":
          newTotal = total.current - number;
          break;
        default:
          newTotal = total.current;
      }
    } while (newTotal < 0);

    total.current = newTotal;

    setQuestion(operation + number.toString());
    questionCounter.current += 1;
    counter.current = 0;
    setCurrentStep(0);
    Stepper(100, stepCallback, changeQuestion);
  };

  const stepCallback = {
    every: 1,
    interval: props.delayInSeconds * 10, // in milliseconds
    callback: (step) => {
      setCurrentStep(step);
      // Call your specific function here for every given step
    },
  };

  React.useEffect(() => {
    if (started) {
      if (props.question === "true") {
        Stepper(100, stepCallback, changeQuestion);
      }
    }
  }, [started]);

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  const navigateToSponsor = (e, id) => {
    navigate("/sponsors/major/" + id);
  };

  // React.useEffect(() => {
  //   if (!mountedRef) {
  //     if (props.audio) {
  //       audioRef.current.play().catch((error) => {
  //         console.error(error);
  //       });
  //     }
  //     mountedRef.current = true;
  //   }
  // }, []);

  const playAudio = () => {
    // Use the ref to access the audio element
    // props.audioRef.current.stop();
    props.audioRef.current.src = "https://peydalan.com.tm" + props.audio;
    try {
      props.setInputFocus();
      props.audioRef.current.play();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div
      className={props.me ? "message me" : "message"}
      onClick={props.audio && playAudio}
    >
      <div className="text-main">
        <div className={props.me ? "text-group me" : "text-group"}>
          <div className={classes}>
            {props.ad ? (
              <div>
                <img
                  onClick={(e) => {
                    navigateToSponsor(e, props.sponsorId);
                  }}
                  src={props.message}
                />
                <div>
                  <i className="material-icons" id="ad-view-i">
                    visibility
                  </i>{" "}
                  {props.view}
                </div>
              </div>
            ) : (
              <div>
                {props.question === "true" ? (
                  <div>
                    <CircularProgressbar
                      value={currentStep}
                      text={`${question}`}
                      styles={buildStyles({ pathTransition: "none" })}
                    />
                    {!started && (
                      <button
                        className="blitz-start-button"
                        onClick={() => {
                          setStarted(true);
                        }}
                      >
                        Start
                      </button>
                    )}
                  </div>
                ) : (
                  <div>{props.message}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function BlitzTestCard(props) {
  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  return (
    <div className={props.me ? "message me" : "message"}>
      <div className="text-main">
        <div className={props.me ? "text-group me" : "text-group"}>
          <div className={classes}>
            <div>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(1);
                }}
                disabled={props.selected}
              >
                1 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(2);
                }}
                disabled={props.selected}
              >
                2 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(3);
                }}
                disabled={props.selected}
              >
                3 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(4);
                }}
                disabled={props.selected}
              >
                4 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(5);
                }}
                disabled={props.selected}
              >
                5 sekunt
              </button>
              <button
                className="blitz-start-button blitz-select-button"
                onClick={() => {
                  props.callback(6);
                }}
                disabled={props.selected}
              >
                6 sekunt
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
