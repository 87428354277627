import React from "react";
import axios from "axios";
import BannerCard from "./cards/BannerCard";
import Card from "./cards/SponsorCard";
import { useParams } from "react-router-dom";
import { SponsorAdminCard } from "./admins/SponsorAdmin";
import { useNavigate } from "react-router-dom";

export function Sponsors(props) {
  const [sponsors, setSponsors] = React.useState([]);
  const [favorites, setFavorites] = React.useState([]);
  const [showFavorites, setShowFavorites] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState();
  const token = localStorage.getItem("token");
  const next = React.useRef(null);
  const ids = React.useRef([]);

  const checkIfIdExistsInArray = (id, array) => {
    return array.find((object) => object.id === id);
  };

  const getUniqueNewId = () => {
    let newId;

    do {
      newId = Math.floor(Math.random() * 100000) + 1;
    } while (checkIfIdExistsInArray(newId, ids.current));

    ids.current.push(newId);
    return newId;
  };

  const addToFavorites = (id) => {
    const updatedData = sponsors.map((item) => {
      if (item.orderId === id) {
        if (item.favorite === true) {
          return { ...item, favorite: false };
        } else {
          return { ...item, favorite: true };
        }
      } else {
        return item;
      }
    });

    setSponsors(updatedData);
  };

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + token,
    };
  }

  function addObjectAfterEvery5Objects(array, object) {
    const newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (i % 5 == 0 && i !== 0) {
        newArray.push({ ...object, banner: true });
      }
      newArray.push(array[i]);
    }
    return setSponsors(newArray);
  }

  const getNext = () => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: "Token " + token,
      };
      axios
        .get(next.current, {
          headers: headers,
        })
        .then((response) => {
          // set the array state to the response data
          if (response.data.next) {
            next.current = response.data.next.replace("http://", "http://");
          } else {
            next.current = null;
          }
          setSponsors((sponsors) => [...sponsors, ...response.data.results]);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    } else {
      axios
        .get(next.current)
        .then((response) => {
          // set the array state to the response data
          if (response.data.next) {
            next.current = response.data.next.replace("http://", "http://");
          } else {
            next.current = null;
          }
          setSponsors((sponsors) => [...sponsors, ...response.data.results]);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    }
  };

  const toggleFavorite = () => {
    setShowFavorites(!showFavorites);
  };

  React.useEffect(() => {
    setFavorites(
      sponsors.filter((object) => {
        return object.favorite === true;
      })
    );
  }, [sponsors]);

  React.useEffect(() => {
    // make an API call using axios

    axios
      .get("https://peydalan.com.tm/api/get-notice-categories", {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data);
        setCategories(response.data);
      })
      .catch((error) => {});
    axios
      .get("https://peydalan.com.tm/api/notice?category=profilim", {
        headers: headers,
      })
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        var result = sponsors;
        result = [...result, ...response.data.results];

        props.notificationsSet({
          ...props.notifications,
          sponsors_small: 0,
          sponsors:
            props.notifications.sponsors_ads +
            props.notifications.sponsors_major,
        });
        axios
          .get("https://peydalan.com.tm/api/video", {
            headers: headers,
          })
          .then((response) => {
            // set the array state to the response data
            result = [...result, ...response.data];

            result = result.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateB - dateA;
            });
            result = result.map((item) => {
              return { ...item, orderId: getUniqueNewId() };
            });
            axios
              .get("https://peydalan.com.tm/api/banner")
              .then((response) => {
                addObjectAfterEvery5Objects(result, response.data);
              })
              .catch((error) => {
                // handle error
                console.error(error);
              });
            // props.notificationsSet({
            //   ...props.notifications,
            //   sponsors_small: 0,
            //   sponsors:
            //     props.notifications.sponsors_ads +
            //     props.notifications.sponsors_major,
            // });
          })
          .catch((error) => {
            // handle error
            console.error(error);
          });
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    console.log(
      "https://peydalan.com.tm/api/notice?category=profilim&profilim_category=" +
        selectedCategory
    );
    axios
      .get(
        "https://peydalan.com.tm/api/notice?category=profilim&profilim_category=" +
          selectedCategory,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }

        setSponsors(response.data.results);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [selectedCategory]);

  return (
    <div className="text-frame">
      <h2>Profilim</h2>
      <div className="product-top">
        <select
          className="break-select padding-0 select-categories"
          value={selectedCategory}
          onChange={(event) => {
            setSelectedCategory(event.target.value);
          }}
        >
          <option value={""}>Kategoriýa Hemmesi</option>
          {categories.map((item, index) => (
            <option value={item.id} key={index}>
              {item.name}
            </option>
          ))}
        </select>
        <button
          className="break-select padding-0 button-favorite"
          onClick={toggleFavorite}
          disabled={!localStorage.getItem("token")}
        >
          <i className="material-icons" id="favorite-i">
            {showFavorites ? "favorite_border" : "favorite"}
          </i>
        </button>
      </div>
      {showFavorites
        ? favorites
            .reverse()
            .map((sponsor) => (
              <Card
                key={sponsor.orderId}
                sponsor={sponsor}
                addToFavorites={addToFavorites}
              />
            ))
        : sponsors
            .reverse()
            .map((sponsor) =>
              sponsor.banner ? (
                <BannerCard banner={sponsor} key={sponsor.orderId} />
              ) : (
                <Card
                  key={sponsor.orderId}
                  sponsor={sponsor}
                  addToFavorites={addToFavorites}
                />
              )
            )}
      {next.current && (
        <button className="button next-button" onClick={getNext}>
          Indiki
        </button>
      )}
    </div>
  );
}

export function MajorSponsors(props) {
  const [sponsors, setSponsors] = React.useState([]);
  const token = localStorage.getItem("token");
  const next = React.useRef(null);

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + token,
    };
  }

  const getNext = () => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: "Token " + token,
      };
      axios
        .get(next.current, {
          headers: headers,
        })
        .then((response) => {
          // set the array state to the response data
          if (response.data.next) {
            next.current = response.data.next.replace("http://", "http://");
          } else {
            next.current = null;
          }
          setSponsors((sponsors) => [...sponsors, ...response.data.results]);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    } else {
      axios
        .get(next.current)
        .then((response) => {
          // set the array state to the response data
          if (response.data.next) {
            next.current = response.data.next.replace("http://", "http://");
          } else {
            next.current = null;
          }
          setSponsors((sponsors) => [...sponsors, ...response.data.results]);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    }
  };

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/notice?category=big-sponsor", {
        headers: headers,
      })
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        setSponsors(response.data.results);
        props.notificationsSet({
          ...props.notifications,
          sponsors_major: 0,
          sponsors:
            props.notifications.sponsors_ads +
            props.notifications.sponsors_small,
        });
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  return (
    <div className="text-frame">
      <h2>Hemaýatçylar</h2>
      {sponsors.map((sponsor) => (
        <Card key={sponsor.id} sponsor={sponsor} />
      ))}
      {next.current && (
        <button className="button next-button" onClick={getNext}>
          Indiki
        </button>
      )}
    </div>
  );
}

export function MajorSponsorDetail() {
  const [sponsor, setSponsor] = React.useState([]);
  const { id } = useParams();

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/notice?id=" + id)
      .then((response) => {
        // set the array state to the response data
        setSponsor(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  return (
    <div className="text-frame">
      <h2>Hemaýatçylar</h2>
      <Card key={sponsor.id} sponsor={sponsor} />
    </div>
  );
}

export function Ads(props) {
  const [sponsors, setSponsors] = React.useState([]);
  const token = localStorage.getItem("token");
  const next = React.useRef(null);
  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + token,
    };
  }

  const getNext = () => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");

      const headers = {
        Authorization: "Token " + token,
      };
      axios
        .get(next.current, {
          headers: headers,
        })
        .then((response) => {
          // set the array state to the response data
          if (response.data.next) {
            next.current = response.data.next.replace("http://", "http://");
          } else {
            next.current = null;
          }
          setSponsors((sponsors) => [...sponsors, ...response.data.results]);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    } else {
      axios
        .get(next.current)
        .then((response) => {
          // set the array state to the response data
          if (response.data.next) {
            next.current = response.data.next.replace("http://", "http://");
          } else {
            next.current = null;
          }
          setSponsors((sponsors) => [...sponsors, ...response.data.results]);
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    }
  };

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/notice?category=ads", {
        headers: headers,
      })
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        setSponsors(response.data.results);
        props.notificationsSet({
          ...props.notifications,
          sponsors_ads: 0,
          sponsors:
            props.notifications.sponsors_major +
            props.notifications.sponsors_small,
        });
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  return (
    <div className="text-frame">
      <h2>Mahabatlar</h2>
      {sponsors.map((sponsor) => (
        <Card key={sponsor.id} sponsor={sponsor} />
      ))}
      {next.current && (
        <button className="button next-button" onClick={getNext}>
          Indiki
        </button>
      )}
    </div>
  );
}

export function MySponsors() {
  const [sponsors, setSponsors] = React.useState([]);
  const next = React.useRef(null);
  const navigate = useNavigate();

  var headers = {};
  if (localStorage.getItem("token")) {
    headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
  }

  React.useEffect(() => {
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/notices-admin", { headers: headers })
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        setSponsors(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, []);

  const getNext = () => {
    axios
      .get(next.current)
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        setSponsors((breaks) => [...breaks, ...response.data.results]);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  const refresh = () => {
    axios
      .get("https://peydalan.com.tm/api/notices-admin", { headers: headers })
      .then((response) => {
        // set the array state to the response data
        if (response.data.next) {
          next.current = response.data.next.replace("http://", "http://");
        } else {
          next.current = null;
        }
        setSponsors(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  const addSponsor = () => {
    navigate("/sponsors/admin/add");
  };

  return (
    <div className="text-frame">
      <div>
        <h2>Profilim Admin</h2>
      </div>
      <div className="product-top">
        <button
          disabled={!localStorage.getItem("admin")}
          className="break-select break-button"
          onClick={addSponsor}
        >
          Profilim goş
        </button>
      </div>
      {sponsors.map((item) => (
        <SponsorAdminCard key={item.id} item={item} refresh={refresh} />
      ))}
      {next.current && (
        <button className="button next-button" onClick={getNext}>
          Indiki
        </button>
      )}
    </div>
  );
}
// AIzaSyAXdLZw3t-fieTDZCmCHzg77QxitBJtvm4
