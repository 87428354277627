import React from "react";
import { CSSProperties } from "react";
import MessageCard from "./cards/MessageCard";
import ReconnectingWebSocket from "reconnecting-websocket";
import addNotification from "react-push-notification";
import { useParams, useLocation } from "react-router-dom";
import { RotateLoader } from "react-spinners";
import axios from "axios";

export function Chat(props) {
  const { room } = useParams();
  const user_id = React.useRef();
  const [messages, setMessages] = React.useState([]);
  var fetched_messages = false;

  React.useEffect(() => {
    if (!localStorage.getItem("token")) {
      setMessages([
        {
          body: "Bize agza bolun  we tazeliklerimiz bilen shu yerde tanshyp durun!",
          room: { name: "channel" },
          login_link: true,
        },
      ]);
      user_id.current = "";
    }
  }, []);

  var client = React.useRef(
    new ReconnectingWebSocket(
      "wss://peydalan.com.tm/ws/chat/all/?token=" +
        localStorage.getItem("token")
    )
  ).current;

  React.useEffect(() => {
    client.onopen = () => {
      console.log("Connected");
      if (!fetched_messages) {
        client.send(
          JSON.stringify({
            command: "fetch_messages",
            room: room,
          })
        );
        client.send(
          JSON.stringify({
            command: "fetch_notifications",
            room: room,
          })
        );
        fetched_messages = true;
      }
    };
    client.onclose = (e) => {
      console.log("Disconnected. Check internet or server.");
    };
    client.onerror = (e) => {
      if (!localStorage.getItem("token")) {
        setMessages([
          {
            body: "Bize agza bolun  we tazeliklerimiz bilen shu yerde tanshyp durun!",
            room: { name: "channel" },
            login_link: true,
          },
        ]);
        user_id.current = "";
      }
    };

    client.onmessage = (message) => {
      const data = JSON.parse(message.data);
      commands[data.type](data.body);
    };
  }, []);

  const deleteMessage = (msg_id) => {
    client.send(
      JSON.stringify({
        msg_id: msg_id,
        command: "delete_message",
      })
    );
  };

  React.useEffect(() => {
    client.send(
      JSON.stringify({
        command: "fetch_messages",
        room: room,
      })
    );
  }, [room]);

  React.useEffect(() => {
    if (props.isAdmin === "admin") {
      client.send(
        JSON.stringify({
          command: "fetch_admin_contacts",
          room: room,
        })
      );
    }
  }, [props.isAdmin]);

  const sendMessage = (data) => {
    client.send(data);
  };

  const newMessage = (data) => {
    setMessages((messages) => [
      ...messages,
      { ...data, me: data.user.id === user_id.current },
    ]);
    addNotification({
      title: data.body,
      native: true,
    });
    if (
      data.room.name !== room &&
      !(room === "admin" && data.room.name === user_id.current.toString())
    ) {
      if (data.user.id !== user_id.current) {
        props.setChatNotificationList((prevData) => [
          ...prevData,
          {
            room: data.room.name,
            user_id: data.user.id,
            username: data.user.username,
            // sent_by_me: data.user,
            me: user_id.current,
          },
        ]);
      }
    }
  };

  const setUserId = (data) => {
    user_id.current = data.user_id;
    if (data.role === "admin") {
      props.setIsAdmin("admin");
    } else if (data.role === "user") {
      props.setIsAdmin("user");
    }
  };

  const loadMessages = (data) => {
    setMessages(data.messages);
  };

  const loadAdminContacts = (data) => {
    props.setContactList(data.contacts);
  };

  const removeMessage = (data) => {
    setMessages((messages) =>
      messages.filter((item) => item.id !== data.msg_id)
    );
  };

  const loadNotifications = (data) => {
    var notifications = [];
    var found_exist = false;
    for (let index = 0; index < data.notifications.length; index++) {
      const element = data.notifications[index];

      props.chatNotificationList.filter((item) => {
        if (item.msg_id === element.message.id) {
          found_exist = true;
        }
      });
      if (!found_exist) {
        if (element.message.user.id !== user_id.current) {
          notifications.push({
            room: element.message.room.name,
            msg_id: element.message.id,
            user_id: element.message.user.id,
            username: element.message.user.username,
            me: user_id.current,
          });
        }
      }
    }
    props.setChatNotificationList((prevData) => [
      ...prevData,
      ...notifications,
    ]);
  };

  const commands = {
    "set.user_id": setUserId,
    "load.messages": loadMessages,
    "new.message": newMessage,
    "load.admin.contacts": loadAdminContacts,
    "delete.message": removeMessage,
    "load.notifications": loadNotifications,
  };

  return (
    <Room
      key={room}
      room={room}
      sendMessage={sendMessage}
      isAdmin={props.isAdmin}
      chatNotificationList={props.chatNotificationList}
      setChatNotificationList={props.setChatNotificationList}
      deleteCallback={deleteMessage}
      windowSize={props.windowSize}
      notifications={props.notifications}
      inputFocusedSet={props.inputFocusedSet}
      inputFocused={props.inputFocused}
      messages={messages.filter((item) => {
        return (
          item.room.name === user_id.current.toString() ||
          item.room.name === room
        );
      })}
    />
  );
}

const override = {
  position: "absolute",
  zIndex: 99,
  top: "70px",
  right: "40%",
};

export function Room(props) {
  const [dialog, setDialog] = React.useState(props.messages);
  const messagesEndRef = React.useRef(null);
  const [message, setMessage] = React.useState("");
  const [menuOpened, setMenuOpened] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState();
  const [previewImage, setPreviewImage] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const imageRef = React.useRef();

  const onFileChange = (event) => {
    // Update the state
    setSelectedImage(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreviewImage(objectUrl);
  };

  const input = React.useRef();
  var room = props.room;

  React.useEffect(() => {
    setDialog(props.messages);
  }, [props.messages]);

  React.useEffect(() => {
    props.setChatNotificationList((prevData) => {
      return prevData.filter((item) => {
        return (
          item.room.toString() !== props.room.toString() &&
          !(props.room === "admin" && item.room === item.me.toString())
        );
      });
    });
    props.sendMessage(
      JSON.stringify({
        type: "remove_notifications",
        command: "remove_notifications",
        room: props.room,
      })
    );
  }, []);

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };

  const sendMessage = (e) => {
    if (selectedImage) {
      setLoading(true);

      const url = "https://peydalan.com.tm/api/chat/image/add";

      const formData = new FormData();
      formData.append("image", selectedImage);

      axios
        .post(url, formData)
        .then((response) => {
          props.sendMessage(
            JSON.stringify({
              type: "new_image_message",
              command: "new_image_message",
              body: message,
              image_id: response.data.img_id,
              room: props.room,
            })
          );
          setLoading(false);
          setPreviewImage(null);
          setSelectedImage(null);
          imageRef.current.value = null;
          setMessage("");
        })
        .catch((error) => {
          setLoading(false);
          console.error("There was an error sending the image:", error);
        });
      return;
    } else if (message) {
      props.sendMessage(
        JSON.stringify({
          type: "new_message",
          command: "new_message",
          body: message,
          room: props.room,
        })
      );
      setMessage("");
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [dialog]);

  return (
    <div
      className="chat"
      id="chat1"
      key={room}
      onClick={() => {
        setMenuOpened(false);
      }}
    >
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <MessageCard
                key={index}
                id={item.id}
                message={item.body}
                menuOpened={menuOpened}
                setMenuOpened={setMenuOpened}
                chat={true}
                type={item.message_type}
                image={item.image}
                scrollToBottom={scrollToBottom}
                deleteCallback={props.deleteCallback}
                login_link={item.login_link}
                me={item.me && "true"}
              />
            ))}
            <div ref={messagesEndRef}></div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          {previewImage && (
            <div className="file-preview">
              <RotateLoader
                loading={loading}
                color="#33A2F4"
                size={50}
                cssOverride={override}
              />
              <div
                className="preview-container"
                style={{ opacity: loading ? "20%" : "100%" }}
              >
                <img
                  src={previewImage}
                  className="img"
                  onLoad={scrollToBottom}
                />
                <button
                  className="remove"
                  onClick={() => {
                    setPreviewImage(null);
                    setSelectedImage("");
                    imageRef.current.value = null;
                  }}
                  disabled={loading}
                >
                  x
                </button>
              </div>
            </div>
          )}
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onChange={handleChange}
                disabled={props.room === "channel" && props.isAdmin !== "admin"}
                rows="0"
              ></textarea>
              <input
                id="image-select"
                type="file"
                onChange={onFileChange}
                ref={imageRef}
                disabled={
                  (props.room === "channel" && props.isAdmin !== "admin") ||
                  loading
                }
              />
              <label className="btn emoticons" htmlFor="image-select">
                <i className="material-icons image-select-label">attach_file</i>
              </label>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  sendMessage();
                }}
                disabled={loading}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
