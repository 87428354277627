import React from "react";
import { useNavigate } from "react-router-dom";

export default function MessageCard(props) {
  const navigate = useNavigate();

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  } ${props.type === "image" ? "image-container" : ""}`;

  const navigateToSponsor = (e, id) => {
    navigate("/sponsors/major/" + id);
  };

  // React.useEffect(() => {
  //   if (!mountedRef) {
  //     if (props.audio) {
  //       audioRef.current.play().catch((error) => {
  //         console.error(error);
  //       });
  //     }
  //     mountedRef.current = true;
  //   }
  // }, []);

  const playAudio = () => {
    // Use the ref to access the audio element
    // props.audioRef.current.stop();
    props.audioRef.current.src = "http://peydalan.com.tm" + props.audio;
    try {
      props.setInputFocus();
      props.audioRef.current.play();
    } catch (e) {
      console.error(e);
    }
  };
  React.useEffect(() => {
    if (props.audio) {
      playAudio();
    }
  }, []);
  // React.useEffect(() => {
  //   try {
  //     playAudio();
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, []);
  return (
    <div
      className={props.me ? "message me" : "message"}
      onClick={props.audio && playAudio}
    >
      <div className="text-main">
        <div className={props.me ? "text-group me" : "text-group"}>
          {props.chat && props.me && props.menuOpened === props.id && (
            <div
              className="dropdown-menu dropdown-menu-right show"
              // x-placement="bottom-end"
            >
              <button
                className="dropdown-item"
                onClick={(e) => {
                  props.deleteCallback(props.id);
                }}
              >
                <i className="material-icons">delete</i>Poz
              </button>
            </div>
          )}
          <div className={classes}>
            {props.chat && props.type === "image" && (
              <img
                src={"https://peydalan.com.tm" + props.image}
                className="chat-img"
                onLoad={props.scrollToBottom}
              />
            )}
            {props.ad ? (
              <div>
                <img
                  onClick={(e) => {
                    navigateToSponsor(e, props.sponsorId);
                  }}
                  src={props.message}
                />
                <div>
                  <i className="material-icons" id="ad-view-i">
                    visibility
                  </i>{" "}
                  {props.view}
                </div>
              </div>
            ) : (
              <div>
                {props.additional3 && <p>{props.additional1}</p>}
                <p className={props.type === "image" ? "p-image" : ""}>
                  {props.message}
                </p>
                {props.login_link && (
                  <a href="/settings" style={{ color: "rgb(1,161,129)" }}>
                    Agza Bol
                  </a>
                )}
              </div>
            )}
          </div>
          {props.chat && props.me && (
            <i
              className="material-icons md-30 chat-more-button"
              onClick={(e) => {
                e.stopPropagation();
                if (props.menuOpened === props.id) {
                  props.setMenuOpened(false);
                } else {
                  props.setMenuOpened(props.id);
                }
              }}
            >
              more_vert
            </i>
          )}
        </div>
      </div>
    </div>
  );
}
