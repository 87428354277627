import React from "react";

export default function AlphabetTestCard(props) {
  const [selectedButton, setSelectedButton] = React.useState(0);
  const audioRef = React.useRef();
  const [answers, setAnswers] = React.useState([]);

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  const selectAnswer = (id) => {
    props.selectedSet(id);
    setSelectedButton(id);
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="message">
      <div className="text-main">
        <div className="text-group">
          <div className={classes}>
            {props.level === 2 ? (
              <img
                src={props.question.image_item}
                onLoad={props.scrollToBottom}
              ></img>
            ) : (
              <img
                src={props.question.image_letter}
                onLoad={props.scrollToBottom}
              ></img>
            )}

            <div className="geography-answers">
              <img
                className={
                  selectedButton &&
                  props.question.letter === props.answer1.letter
                    ? "answer-button selected correct-2"
                    : selectedButton === props.answer1.letter &&
                      props.question.letter !== props.answer1.letter
                    ? "answer-button selected error"
                    : "answer-button"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (!selectedButton) {
                    selectAnswer(props.answer1.letter);
                  }
                }}
                src={
                  props.level === 2
                    ? props.answer1.image_letter
                    : props.answer1.image_item
                }
                disabled={selectedButton && true}
                onLoad={props.scrollToBottom}
              ></img>

              <img
                className={
                  selectedButton &&
                  props.question.letter === props.answer2.letter
                    ? "answer-button selected correct-2"
                    : selectedButton === props.answer2.letter &&
                      props.question.letter !== props.answer2.letter
                    ? "answer-button selected error"
                    : "answer-button"
                }
                src={
                  props.level === 2
                    ? props.answer2.image_letter
                    : props.answer2.image_item
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (!selectedButton) {
                    selectAnswer(props.answer2.letter);
                  }
                }}
                disabled={selectedButton && true}
                onLoad={props.scrollToBottom}
              ></img>
              <img
                className={
                  selectedButton &&
                  props.question.letter === props.answer3.letter
                    ? "answer-button selected correct-2"
                    : selectedButton === props.answer3.letter &&
                      props.question.letter !== props.answer3.letter
                    ? "answer-button selected error"
                    : "answer-button"
                }
                src={
                  props.level === 2
                    ? props.answer3.image_letter
                    : props.answer3.image_item
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (!selectedButton) {
                    selectAnswer(props.answer3.letter);
                  }
                }}
                disabled={selectedButton && true}
                onLoad={props.scrollToBottom}
              ></img>
              <img
                className={
                  selectedButton &&
                  props.question.letter === props.answer4.letter
                    ? "answer-button selected correct-2"
                    : selectedButton === props.answer4.letter &&
                      props.question.letter !== props.answer4.letter
                    ? "answer-button selected error"
                    : "answer-button"
                }
                src={
                  props.level === 2
                    ? props.answer4.image_letter
                    : props.answer4.image_item
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (!selectedButton) {
                    selectAnswer(props.answer4.letter);
                  }
                }}
                onLoad={props.scrollToBottom}
                disabled={selectedButton && true}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
