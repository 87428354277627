import React from "react";
import { Link } from "react-router-dom";

export default function OperationSidebar(props) {
  React.useEffect(() => {
    props.navigationset("games");
  }, []);

  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
  };

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1></h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "operation-1");
            }}
            to="/operation/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "operation-1" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 1</h5>
              <p>1-den 25-e çenli sanlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "operation-2");
            }}
            to="/operation/2"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "operation-2" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 2</h5>
              <p>1-den 50-ä çenli sanlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "operation-3");
            }}
            to="/operation/3"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "operation-3" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 3</h5>
              <p>1-den 100-e çenli sanlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "operation-4");
            }}
            to="/operation/4"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "operation-4" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 4</h5>
              <p>1-den 250-e çenli sanlar</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
