import React from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

export default function Card(props) {
  const [opened, setOpened] = React.useState(false);
  const [current, setCurrent] = React.useState(1);
  const navigate = useNavigate();

  const open = () => {
    setOpened(!opened);
  };

  const jump = (index) => {
    setCurrent(index);
  };

  const change = (value) => {
    var number = value ? 1 : -1;
    current + number > 3
      ? jump(1)
      : current + number < 1
      ? jump(3)
      : setCurrent(current + number);
  };

  const deleteFeedback = () => {
    const headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
    const data = { feedback_id: props.item.id };
    const choice = window.confirm(
      "Bu haryty hakykyatdan hem pozmak isleyarsinizmi?"
    );
    if (choice) {
      axios
        .delete("https://peydalan.com.tm/api/market/feedbacks/delete", {
          headers: headers,
          data: data,
        })
        .then((response) => {
          props.deleteFeedback(props.item.id);
        });
    }
  };

  return (
    <div className="text-content-frame">
      {!opened && props.item.product.image_1 && (
        <img src={props.item.product.image_1} />
      )}
      <div style={{ display: opened ? "block" : "none" }}>
        <div className="slideshow-container">
          {current == 1 && (
            <div>
              <div className="numbertext">1 / 3</div>
              <img src={props.item.product.image_1} className="slide-img" />
            </div>
          )}
          {current == 2 && (
            <div>
              <div className="numbertext">2 / 3</div>
              <img
                src={
                  props.item.product.image_2
                    ? props.item.product.image_2
                    : props.item.product.image
                }
                className="img"
              />
            </div>
          )}
          {current == 3 && (
            <div>
              <div className="numbertext">3 / 3</div>
              <img
                src={
                  props.item.product.image_3
                    ? props.item.product.image_3
                    : props.item.product.image
                }
                className="img"
              />
            </div>
          )}
          <a
            className="prev"
            onClick={() => {
              change(false);
            }}
          >
            &#10094;
          </a>
          <a
            className="next"
            onClick={() => {
              change(true);
            }}
          >
            &#10095;
          </a>
        </div>
      </div>
      <table className="product-table">
        <tbody>
          <tr className="product-row">
            <td className="product-title">Ady:</td>
            <td className="product-info">{props.item.product.name}</td>
          </tr>
          <tr className="product-row">
            <td className="product-title product-price">
              Bahasy:{"  "}
              {props.item.product.price}
            </td>
            <td className="product-info product-price">
              Kody:{"  "}
              {props.item.product.code}
            </td>
          </tr>

          {opened && (
            <tr className="product-row">
              <td className="product-title">Kategoriýasy:</td>
              <td className="product-info">
                {props.item.product.market.category.name}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {opened && (
        <div className="product-market-info">
          {props.item.product.market.name}
          {"  "}
          {props.item.product.market.phone_number}
          {"  "}({props.item.product.market.code})
        </div>
      )}
      {opened && (
        <div className="product-about feedback-body">{props.item.body}</div>
      )}
      {opened && (
        <div className="product-market-info">
          Ýazan: {props.item.user.username}
          {"  "}
          {props.item.user.mobile_no}
        </div>
      )}
      <button
        className="text-button"
        onClick={() => {
          open();
        }}
      >
        {!opened ? "Doly görkez" : "Gizle"}
      </button>
      <button
        className="text-button"
        onClick={() => {
          deleteFeedback();
        }}
      >
        Poz
      </button>
    </div>
  );
}
