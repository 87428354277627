import React from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

function to_velayat(numbers) {
  const values = ["Balkan", "Ahal", "Mary", "Lebap", "Daşoguz", "Aşgabat"];
  var result = [];

  for (let i = 0; i < numbers.length; i++) {
    result.push(values[numbers[i] - 1]);
  }
  var final = result[0];
  for (let i = 1; i < result.length; i++) {
    final += ", " + result[i];
  }
  return final;
}

export default function Card(props) {
  const [opened, setOpened] = React.useState(false);
  const [current, setCurrent] = React.useState(1);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const navigate = useNavigate();

  const open = () => {
    setOpened(!opened);
  };

  const jump = (index) => {
    setCurrent(index);
  };

  const change = (value) => {
    var number = value ? 1 : -1;
    current + number > 2
      ? jump(1)
      : current + number < 1
      ? jump(3)
      : setCurrent(current + number);
  };

  return (
    <div className="text-content-frame">
      {/* <img src={"https://peydalan.com.tm" + props.item.image_1} /> */}
      {!opened && props.item.image_1 && <img src={props.item.image_1} />}
      <div style={{ display: opened ? "block" : "none" }}>
        <div className="slideshow-container">
          {current == 1 && (
            <div>
              <div className="numbertext">1 / 2</div>
              <img src={props.item.image_1} className="slide-img" />
            </div>
          )}
          {current == 2 && (
            <div>
              <div className="numbertext">2 / 2</div>
              <img
                src={props.item.image_2 ? props.item.image_2 : props.item.image}
                className="img"
              />
            </div>
          )}
          <a
            className="prev"
            onClick={() => {
              change(false);
            }}
          >
            &#10094;
          </a>
          <a
            className="next"
            onClick={() => {
              change(true);
            }}
          >
            &#10095;
          </a>
        </div>
      </div>
      <table className="product-table">
        <tbody>
          <tr className="product-row">
            <td className="product-title">Ady:</td>
            <td className="product-info">{props.item.name}</td>
          </tr>
          <tr className="product-row">
            <td className="product-title">Telefon nomeri:</td>
            <td className="product-info">{props.item.phone_number}</td>
          </tr>

          {opened && (
            <tr className="product-row">
              <td className="product-title">Kategoriýasy:</td>
              <td className="product-info">{props.item.category.name}</td>
            </tr>
          )}
          {opened && (
            <tr className="product-row">
              <td className="product-title">Kody:</td>
              <td className="product-info">{props.item.code}</td>
            </tr>
          )}
          {opened && (
            <tr className="product-row">
              <td className="product-title">Yeri:</td>
              <td className="product-info">{to_velayat(props.item.velayat)}</td>
            </tr>
          )}
        </tbody>
      </table>
      {opened && <div className="product-about">{props.item.about}</div>}
      <button
        className="text-button"
        onClick={() => {
          open();
        }}
      >
        {!opened ? "Doly görkez" : "Gizle"}
      </button>
    </div>
  );
}
