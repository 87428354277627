import React from "react";
import MessageCard from "../cards/MessageCard";
import Select, { components, DropdownIndicatorProps } from "react-select";

export default function LearnSentence(props) {
  const [dialog, setDialog] = React.useState([{}]);

  const [isTyping, setIsTyping] = React.useState(false);

  const [options, setOptions] = React.useState([]);
  const [choices, setChoices] = React.useState([]);
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const questions = React.useRef(false);
  // const [correctAnswer, setCorrectAnswer] = React.useState({});
  const correctAnswer = React.useRef(false);
  const correctArray = React.useRef(false);
  const messagesEndRef = React.useRef(null);
  const interval = React.useRef(false); // create a ref // create a state for correct answer

  const welcomeMessage = React.useRef("");
  const input = React.useRef();
  const audioRef = React.useRef();

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (text, audio = null) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        audio: audio,
        message: text,
        me: false,
        info: true,
      },
    ]);
    if (audio) {
      audioRef.current.src = "https://peydalan.com.tmm" + audio;
      audioRef.current.play();
    }
  };

  const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }

    // const sortedArray1 = array1.slice().sort();
    // const sortedArray2 = array2.slice().sort();

    return array1.every(
      (element, index) => element.label === array2[index].label
    );
  };

  const sendMessage = (e) => {
    var flag;
    if (props.lesson.category === "english") {
      flag = "🇺🇸 ";
    } else if (props.lesson.category === "russian") {
      flag = "🇷🇺 ";
    } else if (props.lesson.category === "chinese") {
      flag = "🇨🇳";
    }

    if (choices.length !== 0) {
      var splitter = " ";
      var message = choices
        .map((obj) => {
          if (obj.label.split(" ").length > 1) {
            splitter = "";
          }
          return obj.label.split(" ")[0];
        })
        .join(splitter);
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);
      if (areArraysEqual(choices, correctArray.current)) {
        setOptions([]);
        setIsTyping(true);
        setTimeout(() => {
          sendInfo(
            correctAnswer.current.turkmen,
            correctAnswer.current.audio_turkmen
          );
        }, 1000);

        setTimeout(() => {
          if (correctAnswer.current.additional1) {
            sendInfo(
              correctAnswer.current.answer_another +
                "\n" +
                correctAnswer.current.answer_turkmen +
                "\n" +
                correctAnswer.current.additional1
            );
          } else {
            sendInfo(
              "🇺🇸 " +
                correctAnswer.current.answer_another +
                "\n🇹🇲 " +
                correctAnswer.current.answer_turkmen
            );
          }
        }, 2500);

        setTimeout(() => {
          questions.current = questions.current.filter(
            (q, i) => q.id !== correctAnswer.current.id
          ); // remove the question from the questions array
        }, 2000);
        setTimeout(() => {
          sendQuestion();
        }, 4000); // send another question
      } else {
        setTimeout(() => {
          if (correctAnswer.current.additional1) {
            sendError(
              correctAnswer.current.question +
                "\n" +
                correctAnswer.current.additional1
            );
          } else {
            sendError(correctAnswer.current.question);
          }
        }, 1000);
      }
    }
    setChoices([]);
  };

  const sendQuestion = () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    if (questions.current.length > 0) {
      // check if there are any questions left
      const index = Math.floor(Math.random() * questions.current.length); // get a random index
      const question = questions.current[index];
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          audio: question.audio,
          message: question.question,
          additional1: question.additional1,
          additional3: question.additional1,
          me: false,
        },
      ]);
      const shuffledObjects = shuffleArray(
        question.slug
          .split("_")
          .map((element, index) => ({ label: element, value: index }))
      );
      setOptions(shuffledObjects);
      correctAnswer.current = question;
      correctArray.current = question.slug
        .split("_")
        .map((element, index) => ({ label: element, value: index })); // add the question to the dialog
      if (question.audio) {
        audioRef.current.src = "https://peydalan.com.tmm" + question.audio;
        var playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {})
            .catch((e) => {
              console.error(e);
            });
        }
      }
    } else {
      if (!interval.current) {
        interval.current = setInterval(() => {
          sendQuestion();
        }, 1000);
      }
    }
  };
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  const getQuestions = (dialogs) => {
    var id = 0;
    var newArray = [
      dialogs.map((item) => {
        id += 1;
        return {
          id: id,
          question: item.question_another,
          audio: item.audio_question_another,
          turkmen: item.question_turkmen,
          audio_turkmen: item.audio_question_turkmen,
          additional1: item.additional1,
          answer_another: item.answer_another,
          answer_turkmen: item.answer_turkmen,
          slug: item.question_another_slug,
        };
      }),
    ][0];
    newArray = [
      ...newArray,
      // [
      ...dialogs.map((item) => {
        id += 1;
        return {
          id: id,
          question: item.answer_another,
          audio: item.audio_answer_another,
          turkmen: item.answer_turkmen,
          audio_turkmen: item.audio_answer_turkmen,
          additional1: item.additional2,
          answer_another: item.question_another,
          answer_turkmen: item.question_turkmen,
          slug: item.answer_another_slug,
        };
      }),
    ];
    return newArray;
  };

  React.useEffect(() => {
    if (questions.current.length === 0) {
      if (Object.keys(correctAnswer.current).length !== 0) {
        setTimeout(() => {
          sendInfo("Berekella, indiki aylawymyzy dowam edelin");
        }, 1000);
        setTimeout(() => {
          const data = getQuestions(props.dialogs);
          questions.current = data;
          sendQuestion();
        }, 2000);
        return;
      }
    }
  }, [questions.current]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (props.lesson) {
      var language = "";
      if (props.lesson.category === "english") {
        language = "Iňlis dili";
      } else if (props.lesson.category === "russian") {
        language = "Rus dili";
      } else if (props.lesson.category === "chinese") {
        language = "Hytaý dili";
      }
      welcomeMessage.current =
        "Salam! " +
        language +
        " öwrenmegiň 4-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulylymyz 1-2-3-nji ädimlerde öwrenen sözlerimizden sözlem düzmäge türgenleşeris, ýagny men size düzen sözlemlerimden ugradaryn siz ýene öňkiler ýaly göçirip yzyna barlaga ugratmaly, Türgenleşigmiziň bu usuly sözleri nähili goşulup sözlem ýazylýandygyny aýdyň eder, we elleriňizi klawatura ulanmaga uýgunlaşdyrar. Good Luck!";
    }
  }, [props.lesson]);

  React.useEffect(() => {
    if (!props.ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: props.ad.image,
          me: false,
          ad: true,
          view: props.ad.view,
          id: props.ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [props.ad]);

  const setInputFocus = () => {
    input.current.focus();
  };

  const handleInputChange = (data) => {
    setChoices(data);
  };

  React.useEffect(() => {
    if (props.dialogs.length !== 0) {
      const data = getQuestions(props.dialogs);
      questions.current = data;
      // Set the state with the data
    }
  }, [props.dialogs]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator
        {...props}
        innerProps={{
          ...props.innerProps,
          onMouseDown: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          onTouchEnd: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
        }}
      >
        <i
          onMouseDown={() => sendMessage()}
          onTouchEnd={() => sendMessage()}
          className="material-icons"
        >
          send
        </i>
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio hidden ref={audioRef} />
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) => (
              <MessageCard
                key={index}
                ad={item.ad}
                audioRef={audioRef}
                setInputFocus={setInputFocus}
                view={item.view}
                sponsorId={item.id}
                message={item.message}
                additional1={item.additional1}
                additional2={item.additional2}
                additional3={item.additional3}
                audio={item.audio}
                me={item.me && "true"}
                error={item.error && "true"}
                info={item.info && "true"}
              />
            ))}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              {/* <textarea
                  className="form-control"
                  placeholder="Jogap ýaz..."
                  value={message}
                  ref={input}
                  onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                  onFocus={() => {
                    props.inputFocusedSet(true);
                  }}
                  onBlurCapture={() => {
                    props.inputFocusedSet(false);
                  }}
                  onChange={handleChange}
                  rows="0"
                ></textarea>
                <button
                  type="button"
                  className="btn send"
                  onFocus={(e) => {
                    sendMessage(e);
                  }}
                >
                  <i className="material-icons">send</i>
                </button> */}
              <Select
                defaultMenuIsOpen
                components={{ DropdownIndicator }}
                closeMenuOnSelect={false}
                isMulti
                options={options}
                ref={input}
                value={choices}
                onChange={handleInputChange}
                classNamePrefix="react-select"
                hideSelectedOptions={false}
                menuIsOpen={true}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
