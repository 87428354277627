import React from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

export default function TextCard(props) {
  const [words, setWords] = React.useState([]);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const navigate = useNavigate();

  const audioRef = React.useRef();

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  React.useEffect(() => {
    if (props.audio) {
      audioRef.current.play().catch((error) => {
        console.error(error);
      });
    }
    if (localStorage.getItem("token")) {
      const headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
      axios
        .get(
          "https://peydalan.com.tm/api/dictionary/get-words-of-text?text_id=" +
            props.id,
          {
            headers: headers,
          }
        )
        .then((res) => {
          setWords(res.data);
        })
        .catch((err) => {
          console.error(err.request.data);
        });
    }
  }, []);

  const playAudio = () => {
    // Use the ref to access the audio element
    audioRef.current.play();
  };

  const addToKnownWords = (word) => {
    if (localStorage.getItem("token")) {
      const headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
      axios
        .post(
          "https://peydalan.com.tm/api/dictionary/add_to_known_words",
          { word: word },
          {
            headers: headers,
          }
        )
        .then((res) => {
          updateIsKnown(word, true);
        })
        .catch((err) => {
          console.log(err.request.data);
        });
    } else {
      setPopupOpened(true);
    }
  };

  const removeFromKnownWords = (word) => {
    if (localStorage.getItem("token")) {
      const headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
      axios
        .post(
          "https://peydalan.com.tm/api/dictionary/remove_from_known_words",
          { word: word },
          {
            headers: headers,
          }
        )
        .then((res) => {
          updateIsKnown(word, false);
        })
        .catch((err) => {
          console.log(err.request.data);
        });
    }
  };

  const updateIsKnown = (translationToUpdate, state) => {
    const updatedWords = words.map((word) =>
      translationToUpdate === word.translation
        ? { ...word, is_known: state }
        : word
    );
    setWords(updatedWords);
  };

  const searchWord = (word) => {
    const searchTerm1 = word.toLowerCase();
    const filteredWords = words.filter((item) => {
      const translation = item.translation.toLowerCase();
      return translation === searchTerm1;
    });

    if (filteredWords.length === 0) {
      // Not Found
    } else {
      return filteredWords[0].is_known;
    }
  };

  const completedWords = props.message.split(/\[(.*?)\]/).map((part, index) => {
    if (index % 2 === 0) {
      return part; // Non-bracketed text
    } else {
      const cleanWord = part; //.slice(0, -1); // Remove the closing bracket
      return (
        <h5
          key={index}
          onClick={() => props.callback(cleanWord)}
          style={{
            display: "inline",
            cursor: "pointer",
            width: "100%",
            color:
              cleanWord === props.selectedWord.translation
                ? "rgb(70, 126, 70) "
                : "inherit",
          }}
        >
          {cleanWord}
          <button
            onClick={() => {
              searchWord(cleanWord)
                ? removeFromKnownWords(cleanWord)
                : addToKnownWords(cleanWord);
            }}
            style={{
              backgroundColor: searchWord(cleanWord)
                ? "green"
                : "var(--background-secondary)",
              position: "absolute",
              right: -40,
              color: "var(--text-secondary)",
              border: "1px solid var(--text-secondary)",
            }}
          >
            ✔
          </button>
          <br />
        </h5>
      );
    }
  });
  // if (!props.message) {
  //   return;
  // }
  return (
    <div className="message" onClick={props.audio && playAudio}>
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">
              Bu aýratynlygy ulanmak üçin agza boluň!
            </div>
            <div className="actions">
              <button
                className="button button-login"
                onClick={() => {
                  navigate("/settings");
                }}
              >
                {" "}
                Giriş{" "}
              </button>
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                Ýok, Sagboluň
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      <div className="text-main">
        <div className="text-group">
          <div className={classes} style={{ position: "relative" }}>
            {props.audio && (
              <audio
                ref={audioRef}
                src={"https://peydalan.com.tm" + props.audio}
                hidden
              />
            )}
            <h5>{completedWords}</h5>
          </div>
        </div>
      </div>
    </div>
  );
}
