import React from "react";
import { Link } from "react-router-dom";

export default function KaraokeSidebar(props) {
  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
    // setTimeout(() => {}, 1000);
  };

  React.useEffect(() => {
    props.navigationset("games");
  }, []);

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Karaoke</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "chooseLanguage");
            }}
            to="/karaoke"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chooseLanguage" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Dil saýla</h5>
              <p>Aýdymyň dilini saýla</p>
            </div>
          </Link>
          {props.karaokeSidebarItems.map((item, index) => {
            return (
              <Link
                onClick={(e) => {
                  handleClick(e, item.id);
                }}
                to={"/karaoke/" + item.id}
                className={
                  "filterDiscussions  single all unread " +
                  (props.menu === item.id && "active")
                }
                id="list-chat-list"
                data-toggle="list"
                role="tab"
              >
                <div className="data">
                  <h5>{item.name}</h5>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
