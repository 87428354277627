import React from "react";

import MessageCard from "../cards/MessageCard";
import AudioMessage from "../utils/AudioMessage";

const slugify = (text) => {
  return (
    text
      .toString()
      // .normalize("NFKD")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "")
      .replace(/[.!?,]/g, "")
      .replace(/\-\-+/g, "")
      .replace(/;/g, "")
      .replace(/'/g, "")
      .replace(/"/g, "")
      .replace(/,/g, "")
  );
};

export default function AudioWord(props) {
  const [dialog, setDialog] = React.useState([{}]);

  const [statistics, setStatistics] = React.useState({
    corrects: 0,
    errors: 0,
  });

  const [errored, setErrored] = React.useState(false);

  const [isTyping, setIsTyping] = React.useState(false);

  const [message, setMessage] = React.useState("");
  // const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const questions = React.useRef(false);
  // const [correctAnswer, setCorrectAnswer] = React.useState({});
  const correctAnswer = React.useRef(false);
  const messagesEndRef = React.useRef(null); // create a ref // create a state for correct answer

  const input = React.useRef();
  const hasSetLocalState = React.useRef(false);
  const interval = React.useRef(false);
  const welcomeMessage = React.useRef("");
  const audioRef = React.useRef();

  const handleChange = (e) => {
    // Set the text state to the textarea value
    setMessage(e.target.value);
  };

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (text, audio = null) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        audio: audio,
        message: text,
        me: false,
        info: true,
      },
    ]);
    if (audio) {
      audioRef.current.src = "https://peydalan.com.tm" + audio;
      audioRef.current.play();
    }
  };

  const sendMessage = (e) => {
    var flag;
    if (props.lesson.category === "english") {
      flag = "🇺🇸 ";
    } else if (props.lesson.category === "russian") {
      flag = "🇷🇺 ";
    } else if (props.lesson.category === "chinese") {
      flag = "🇨🇳";
    }

    if (message && message !== "\n") {
      setDialog((dialogs) => [...dialogs, { message: message, me: true }]);
      if (
        slugify(message.toLowerCase()) ===
          slugify(correctAnswer.current.another_lang.toLowerCase()) ||
        message.replaceAll(" ", "").replaceAll("\n", "") ===
          correctAnswer.current.additional1.replaceAll(" ", "")
      ) {
        if (!errored) {
          setStatistics({ ...statistics, corrects: statistics.corrects + 1 });
        } else {
          setStatistics({ ...statistics, errors: statistics.errors + 1 });
        }
        setIsTyping(true);
        setTimeout(() => {
          sendInfo(
            correctAnswer.current.turkmen,
            correctAnswer.current.audio_turkmen
          );
        }, 1000);

        setTimeout(() => {
          if (correctAnswer.current.additional3) {
            sendInfo(
              correctAnswer.current.additional2 +
                "\n" +
                correctAnswer.current.additional3 +
                "\n" +
                correctAnswer.current.description
            );
          } else {
            sendInfo(
              flag +
                correctAnswer.current.description +
                "\n🇹🇲 " +
                correctAnswer.current.additional1
            );
          }
        }, 2500);

        setTimeout(() => {
          questions.current = questions.current.filter(
            (q, i) => q.id !== correctAnswer.current.id
          ); // remove the question from the questions array
        }, 2000);
        setTimeout(() => {
          sendQuestion();
        }, 4000); // send another question
      } else {
        setErrored(true);
        setTimeout(() => {
          if (correctAnswer.current.additional3) {
            sendError(
              correctAnswer.current.another_lang +
                "\n" +
                correctAnswer.current.additional1
            );
          } else {
            sendError(correctAnswer.current.another_lang);
          }
        }, 1000);
      }
    }
    input.current.focus();
    setMessage("");
  };

  const setInputFocus = () => {
    input.current.focus();
  };

  const sendQuestion = () => {
    if (questions.current.length > 0) {
      if (interval.current) {
        clearInterval(interval.current);
      }
      setErrored(false);
      // check if there are any questions left
      const index = Math.floor(Math.random() * questions.current.length); // get a random index
      const question = questions.current[index];
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          audio: question.audio_another,
          message: question.another_lang,
          me: false,
          audioMessage: true,
        },
      ]);
      correctAnswer.current = question; // add the question to the dialog
    } else {
      if (!interval.current) {
        interval.current = setInterval(() => {
          sendQuestion();
        }, 1000);
      }
    }
  };

  React.useEffect(() => {
    if (questions.current.length === 0) {
      if (Object.keys(correctAnswer.current).length !== 0) {
        setTimeout(() => {
          sendInfo(
            "Berekella, indiki aylawymyzy dowam edelin \nDogry sany: " +
              statistics.corrects +
              "\nÝalňyş sany:" +
              statistics.errors
          );
          setStatistics({ corrects: 0, errors: 0 });
        }, 1000);
        setTimeout(() => {
          questions.current = props.words;
          sendQuestion();
        }, 2000);
        return;
      }
    }
  }, [questions.current]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendQuestion();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (props.lesson) {
      var language = "";
      if (props.lesson.category === "english") {
        language = "Iňlis dili";
      } else if (props.lesson.category === "russian") {
        language = "Rus dili";
      } else if (props.lesson.category === "chinese") {
        language = "Hytaý dili";
      }
      welcomeMessage.current =
        "Salam! " +
        language +
        " öwrenmegiň 2-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulylymyz 1-nji ädimde ýazylşyny öwrenen sözlermiziň sesleri ugradylar, siz bolsa ol sesli sözleri diňläp dogry hata geçirip bize ugratmaly, biz siziň ýazgyňyz barlap ýene bir gezek terjimesini we ol söziň söz içinde getirlen sözlemini ugradaryn, Öwrenmegiň bu usuly siziň diňläp düşinmek ukybyňyzy türgenleşdirer. Good Luck!";
    }
  }, [props.lesson]);

  React.useEffect(() => {
    if (!props.ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: props.ad.image,
          me: false,
          ad: true,
          view: props.ad.view,
          id: props.ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [props.ad]);

  // React.useEffect(, [questions])

  React.useEffect(() => {
    if (props.words.length !== 0 && !hasSetLocalState.current) {
      hasSetLocalState.current = true;
      questions.current = props.words;
      // setQuestions(props.words);
      // Set the state with the data
    }
  }, [props.words]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom(); // scroll to bottom when messages change
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <audio hidden ref={audioRef} />
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              item.audioMessage ? (
                <AudioMessage
                  key={index}
                  setInputFocus={setInputFocus}
                  audio={"https://peydalan.com.tm" + item.audio}
                />
              ) : (
                <MessageCard
                  key={index}
                  audioRef={audioRef}
                  setInputFocus={setInputFocus}
                  ad={item.ad}
                  view={item.view}
                  sponsorId={item.id}
                  message={item.message}
                  audio={item.audio}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                />
              )
            )}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                className="form-control"
                placeholder="Jogap ýaz..."
                value={message}
                ref={input}
                onKeyUp={(e) => (e.key === "Enter" ? sendMessage() : "")}
                onFocus={() => {
                  props.inputFocusedSet(true);
                }}
                onBlurCapture={() => {
                  props.inputFocusedSet(false);
                }}
                onChange={handleChange}
                rows="0"
              ></textarea>
              <button
                type="button"
                className="btn send"
                onFocus={(e) => {
                  sendMessage(e);
                }}
              >
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
