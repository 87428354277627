import React from "react";
import { Link } from "react-router-dom";

export default function MathSidebar(props) {
  React.useEffect(() => {
    props.navigationset("games");
  }, []);

  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
  };

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Matematika</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "math_choose");
            }}
            to="/math/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math_choose" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Sapak Sayla</h5>
              <p>Bashlamak uchin sapak saylan</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "math_level1");
            }}
            to="/math/level/1"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math_level1" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Adim 1</h5>
              <p>25-a chenli sanlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "math_level2");
            }}
            to="/math/level/2"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math_level2" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Adim 2</h5>
              <p>50-a chenli sanlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "math_level3");
            }}
            to="/math/level/3"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math_level3" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Adim 3</h5>
              <p>100-a chenli sanlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "math_level4");
            }}
            to="/math/level/4"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math_level4" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Adim 4</h5>
              <p>250-a chenli sanlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "math_level5");
            }}
            to="/math/level/5"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math_level5" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Adim 5</h5>
              <p>500-a chenli sanlar</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "math_level6");
            }}
            to="/math/level/6"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "math_level6" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Adim 6</h5>
              <p>1000-a chenli sanlar</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
