import React from "react";
import { Link } from "react-router-dom";

export default function AboutSidebar(props) {
  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
    // setTimeout(() => {}, 1000);
  };

  React.useEffect(() => {
    props.navigationset("settings");
  }, []);

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Hakynda Bölümleri</h1>
        <div className="list-group" id="chats" role="tablist">
          {localStorage.getItem("token") ? (
            <Link
              onClick={(e) => {
                handleClick(e, "login");
              }}
              to="/profile"
              className={
                "filterDiscussions  single all unread " +
                (props.menu === "login" && "active")
              }
              id="list-chat-list"
              data-toggle="list"
              role="tab"
            >
              <div className="data">
                <h5>Şahsy otag</h5>
                <p>Şahsy otag bölümi</p>
              </div>
            </Link>
          ) : (
            <Link
              onClick={(e) => {
                handleClick(e, "login");
              }}
              to="/settings/"
              className={
                "filterDiscussions  single all unread " +
                (props.menu === "login" && "active")
              }
              id="list-chat-list"
              data-toggle="list"
              role="tab"
            >
              <div className="data">
                <h5>Ulgama gir</h5>
                <p>Ulgama girmek üçin</p>
              </div>
            </Link>
          )}
          <Link
            onClick={(e) => {
              handleClick(e, "about");
            }}
            to="/about"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "about" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Websaýt Hakynda</h5>
              <p>Biziň websaýtymyz barada umumy maglumatlar...</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "games");
            }}
            to="/about/games"
            className={
              props.menu === "games"
                ? "filterDiscussions  single all unread active"
                : "filterDiscussions  single all unread "
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Okuw oýunlary hakynda</h5>
              <p>Okuw oýunlary hakynda umumy maglumatlar...</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "lesson");
            }}
            to="/about/lesson"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "lesson" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Sözlük öwrenmek barada</h5>
              <p>Sözlük öwrenmek barada umumy maglumatlar...</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "chat");
            }}
            to="/about/chat"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chat" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Aragatnaşyk barada</h5>
              <p>Aragatnaşyk barada umumy maglumatlar...</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "sponsors");
            }}
            to="/about/sponsors"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "sponsors" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Hemaýatçylar barada</h5>
              <p>Hemaýatçylar barada umumy maglumatlar...</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "market");
            }}
            to="/about/market"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "market" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Market barada</h5>
              <p>Market barada umumy maglumatlar...</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "contact");
            }}
            to="/settings/contact"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "contact" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Habarlaş</h5>
              <p>Biz bilen habarlaşyň</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
