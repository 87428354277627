import React from "react";
import { Link } from "react-router-dom";
import Badge from "../utils/Badge";
import axios from "axios";

export default function SponsorSidebar(props) {
  React.useEffect(() => {
    props.navigationset("sponsors");
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      const headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
      axios
        .get("https://peydalan.com.tm/api/notice-admin/check", {
          headers: headers,
        })
        .then((response) => {
          // set the array state to the response data
          if (response.data.canAddNotice) {
            localStorage.setItem("admin", true);
          }
        })
        .catch((error) => {
          // handle error
          localStorage.removeItem("admin");
        });
    }
  }, []);

  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
  };

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Hemaýatçylar Bölümleri</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "all");
            }}
            to="/sponsors"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "all" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              {props.notifications.sponsors_small > 0 && (
                <Badge
                  count={props.notifications.sponsors_small}
                  place="sidebar"
                />
              )}
              <h5>Profilimiz</h5>
              <p>Täzeliklerimiz</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "major");
            }}
            to="/sponsors/major"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "major" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              {props.notifications.sponsors_major > 0 && (
                <Badge
                  count={props.notifications.sponsors_major}
                  place="sidebar"
                />
              )}
              <h5>Hemaýatçylar</h5>
              <p>Websaýtymyzyň işine ýakyndan hemaýat edýänler</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "ads");
            }}
            to="/sponsors/ads"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "ads" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              {props.notifications.sponsors_ads > 0 && (
                <Badge
                  count={props.notifications.sponsors_ads}
                  place="sidebar"
                />
              )}
              <h5>Mahabatlar</h5>
              <p>Mahabat goýup bermek hyzmaty</p>
            </div>
          </Link>
          {localStorage.getItem("admin") && (
            <Link
              onClick={(e) => {
                handleClick(e, "ads");
              }}
              to="/sponsors/admin"
              className={
                "filterDiscussions  single all unread " +
                (props.menu === "ads" && "active")
              }
              id="list-chat-list5"
              data-toggle="list"
              role="tab"
            >
              <div className="data">
                <h5>Profilim Goshmak</h5>
                <p>Profilim goshmak</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
