import React from "react";

export default function ChemistryTestCard(props) {
  const [selectedButton, setSelectedButton] = React.useState(0);
  const audioRef = React.useRef();
  const [answers, setAnswers] = React.useState([]);

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  React.useEffect(() => {
    setAnswers(
      shuffleArray([
        props.question.symbol,
        props.question.wrong_answer_1,
        props.question.wrong_answer_2,
        props.question.wrong_answer_3,
      ])
    );
  }, [props.question]);

  const selectAnswer = (id) => {
    props.selectedSet(id);
    setSelectedButton(id);
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  return (
    <div className="message">
      <div className="text-main">
        <div className="text-group">
          {props.mode === "1" ? (
            <div className={classes}>
              <div className="chemistry-element-card" onClick={playAudio}>
                <h1 className="symbol">
                  {selectedButton ? props.question.symbol : "?"}
                </h1>
                <h4 className="name">
                  {props.question.name_en} <br /> {props.question.name_tk}{" "}
                </h4>
                <h5 className="order">{props.question.order}</h5>
                <h5 className="order">{props.question.mass}</h5>
              </div>
              <audio src={props.question.audio} autoPlay ref={audioRef} />
              <div className="geography-answers">
                <button
                  className={
                    selectedButton && props.question.symbol === answers[0]
                      ? "answer-button selected chemistry-correct"
                      : selectedButton === answers[0] &&
                        props.question.symbol !== answers[0]
                      ? "answer-button selected chemistry-error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(answers[0]);
                    }
                  }}
                  disabled={selectedButton && true}
                >
                  {" "}
                  {answers[0]}
                </button>

                <button
                  className={
                    selectedButton && props.question.symbol === answers[1]
                      ? "answer-button selected  chemistry-correct"
                      : selectedButton === answers[1] &&
                        props.question.symbol !== answers[1]
                      ? "answer-button selected chemistry-error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(answers[1]);
                    }
                  }}
                  disabled={selectedButton && true}
                >
                  {" "}
                  {answers[1]}
                </button>
                <button
                  className={
                    selectedButton && props.question.symbol === answers[2]
                      ? "answer-button selected  chemistry-correct"
                      : selectedButton === answers[2] &&
                        props.question.symbol !== answers[2]
                      ? "answer-button selected chemistry-error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(answers[2]);
                    }
                  }}
                  disabled={selectedButton && true}
                >
                  {" "}
                  {answers[2]}
                </button>
                <button
                  className={
                    selectedButton && props.question.symbol === answers[3]
                      ? "answer-button selected  chemistry-correct"
                      : selectedButton === answers[3] &&
                        props.question.symbol !== answers[3]
                      ? "answer-button selected chemistry-error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(answers[3]);
                    }
                  }}
                  disabled={selectedButton && true}
                >
                  {" "}
                  {answers[3]}
                </button>
              </div>
            </div>
          ) : (
            <div className={classes}>
              <div className="chemistry-element-card">
                <h1 className="symbol">{props.question.symbol}</h1>
                <h4 className="name">
                  {selectedButton ? (
                    <div>
                      {props.question.name_en}
                      <br /> {props.question.name_tk}
                    </div>
                  ) : (
                    "?"
                  )}{" "}
                </h4>
                <h5 className="order">{props.question.order}</h5>
                <h5 className="order">{props.question.mass}</h5>
              </div>
              <div className="geography-answers">
                <button
                  className={
                    selectedButton &&
                    props.question.name_en === props.answer1.name_en
                      ? "answer-button selected  chemistry-correct"
                      : selectedButton === props.answer1.name_en &&
                        props.question.name_en !== props.answer1.name_en
                      ? "answer-button selected chemistry-error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.answer1.name_en);
                    }
                  }}
                  disabled={selectedButton && true}
                >
                  {" "}
                  {props.answer1.name_en}
                </button>

                <button
                  className={
                    selectedButton &&
                    props.question.name_en === props.answer2.name_en
                      ? "answer-button selected  chemistry-correct"
                      : selectedButton === props.answer2.name_en &&
                        props.question.name_en !== props.answer2.name_en
                      ? "answer-button selected chemistry-error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.answer2.name_en);
                    }
                  }}
                  disabled={selectedButton && true}
                >
                  {" "}
                  {props.answer2.name_en}
                </button>
                <button
                  className={
                    selectedButton &&
                    props.question.name_en === props.answer3.name_en
                      ? "answer-button selected  chemistry-correct"
                      : selectedButton === props.answer3.name_en &&
                        props.question.name_en !== props.answer3.name_en
                      ? "answer-button selected chemistry-error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.answer3.name_en);
                    }
                  }}
                  disabled={selectedButton && true}
                >
                  {" "}
                  {props.answer3.name_en}
                </button>
                <button
                  className={
                    selectedButton &&
                    props.question.name_en === props.answer4.name_en
                      ? "answer-button selected  chemistry-correct"
                      : selectedButton === props.answer4.name_en &&
                        props.question.name_en !== props.answer4.name_en
                      ? "answer-button selected chemistry-error"
                      : "answer-button"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedButton) {
                      selectAnswer(props.answer4.name_en);
                    }
                  }}
                  disabled={selectedButton && true}
                >
                  {" "}
                  {props.answer4.name_en}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
