import React from "react";
import { Link } from "react-router-dom";
import Badge from "../utils/Badge";

export default function ChatSidebar(props) {
  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
    // setTimeout(() => {}, 1000);
  };

  React.useEffect(() => {
    props.navigationset("chat");
  }, []);

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Chat Bölümleri</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "chat-channel");
            }}
            to="/chat/channel"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "chat-channel" && "active ") +
              (!localStorage.getItem("token") && " disabled-link")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              {props.chatNotificationList.filter((item) => {
                return (
                  item.room === "channel" && item.user_id != item.me.toString()
                );
              }).length > 0 && (
                <Badge
                  count={
                    props.chatNotificationList.filter((item) => {
                      return (
                        item.room === "channel" &&
                        item.user_id != item.me.toString()
                      );
                    }).length
                  }
                  place="sidebar"
                />
              )}
              <h5>Channel</h5>
              <p>Channel</p>
            </div>
          </Link>
          {props.isAdmin && props.isAdmin !== "admin" ? (
            <Link
              onClick={(e) => {
                handleClick(e, "chat-admin");
              }}
              to="/chat/admin"
              className={
                props.menu === "chat-admin"
                  ? "filterDiscussions  single all unread active"
                  : "filterDiscussions  single all unread "
              }
              id="list-chat-list5"
              data-toggle="list"
              role="tab"
            >
              <div className="data">
                {props.chatNotificationList.filter((item) => {
                  return (
                    item.room === item.me.toString() &&
                    item.user_id != item.me.toString()
                  );
                }).length > 0 && (
                  <Badge
                    count={
                      props.chatNotificationList.filter((item) => {
                        return (
                          item.room === item.me.toString() &&
                          item.user_id != item.me.toString()
                        );
                      }).length
                    }
                    place="sidebar"
                  />
                )}
                <h5>Admin</h5>
                <p>Admin bilen gurlesh</p>
              </div>
            </Link>
          ) : (
            props.isAdmin &&
            props.isAdmin === "admin" && (
              <div>
                {props.contactList.map((item, index) => {
                  return (
                    <Link
                      key={index}
                      onClick={(e) => {
                        handleClick(e, "chat-" + index.toString());
                      }}
                      to={"/chat/" + item.id}
                      className={
                        props.menu === "chat-" + index.toString()
                          ? "filterDiscussions  single all unread active"
                          : "filterDiscussions  single all unread "
                      }
                      id="list-chat-list5"
                      data-toggle="list"
                      role="tab"
                    >
                      <div className="data">
                        {props.chatNotificationList.filter((i) => {
                          return (
                            i.username === item.username &&
                            i.user_id != i.me.toString()
                          );
                        }).length > 0 && (
                          <Badge
                            count={
                              props.chatNotificationList.filter((i) => {
                                return (
                                  i.username === item.username &&
                                  i.user_id != i.me.toString()
                                );
                              }).length
                            }
                            place="sidebar"
                          />
                        )}
                        <h5>{item.username}</h5>
                      </div>
                    </Link>
                  );
                })}
              </div>
            )
          )}
          {!props.isAdmin && (
            <Link
              onClick={(e) => {
                handleClick(e, "chat-admin");
              }}
              to="/chat/admin"
              className={
                props.menu === "chat-admin"
                  ? "filterDiscussions  single all unread active disabled-link"
                  : "filterDiscussions  single all unread  disabled-link"
              }
              id="list-chat-list5"
              data-toggle="list"
              role="tab"
            >
              <div className="data">
                {props.chatNotificationList.filter((item) => {
                  return (
                    item.room === item.me.toString() &&
                    item.user_id != item.me.toString()
                  );
                }).length > 0 && (
                  <Badge
                    count={
                      props.chatNotificationList.filter((item) => {
                        return (
                          item.room === item.me.toString() &&
                          item.user_id != item.me.toString()
                        );
                      }).length
                    }
                    place="sidebar"
                  />
                )}
                <h5>Admin</h5>
                <p>Admin bilen gurlesh</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
