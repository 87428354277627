import React from "react";

export default function TestTextCard(props) {
  const audioRef = React.useRef();

  let classes = `text ${props.me ? "me" : ""} ${props.error ? "error" : ""} ${
    props.info ? "info" : ""
  }`;

  React.useEffect(() => {
    if (props.audio) {
      audioRef.current.play().catch((error) => {
        console.error(error);
      });
    }
  }, []);

  const playAudio = () => {
    // Use the ref to access the audio element
    audioRef.current.play();
  };
  // if (!props.message) {
  //   return;
  // }
  return (
    <div className="message" onClick={props.audio && playAudio}>
      <div className="text-main">
        <div className="text-group">
          <div className={classes}>
            {props.audio && (
              <audio
                ref={audioRef}
                src={"https://peydalan.com.tm" + props.audio}
                hidden
              />
            )}
            <h6>{props.message}</h6>
            <button
              className="answer-button"
              onClick={props.onClick}
              disabled={props.disabled && true}
            >
              {props.button}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
