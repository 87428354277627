import React from "react";
import { Link } from "react-router-dom";

export default function LessonSidebar(props) {
  React.useEffect(() => {
    props.navigationset("lesson");
  }, []);

  const handleClick = (e, value) => {
    props.setChecked();
    props.setMenu(value);
  };

  return (
    <div id="discussions" className="tab-pane fade active show">
      <div className="discussions">
        <h1>Sapak Bölümleri</h1>
        <div className="list-group" id="chats" role="tablist">
          <Link
            onClick={(e) => {
              handleClick(e, "lesson");
            }}
            to="/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "lesson" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Sapak saýla</h5>
              <p>Öwrenmek üçin dil we sapak saýlaň</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "text");
            }}
            to="/lesson/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "text" && "active")
            }
            id="list-chat-list"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Mazmuny</h5>
              <p>Türkmen dili we daşary ýurt dilini deňeşdirmek üçin</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "learn-word");
            }}
            to="/lesson/word/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "learn-word" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 1</h5>
              <p>Sözleri bot bilen ýazyşyp öwreniň</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "word-audio");
            }}
            to="/lesson/word/audio/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "word-audio" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 2</h5>
              <p>Sözleriň aýdylyşynyň bot bilen ýazyşyp öwreniň</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "word-test");
            }}
            to="/lesson/word/test/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "word-test" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 3</h5>
              <p>Öwrenen sözleriňizi test ediň</p>
            </div>
          </Link>

          <Link
            onClick={(e) => {
              handleClick(e, "learn-sentence");
            }}
            to="/lesson/sentence/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "learn-sentence" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 4</h5>
              <p>Sözlemi bot bilen ýazyşyp öwreniň</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "sentence-audio");
            }}
            to="/lesson/sentence/audio/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "sentence-audio" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 5</h5>
              <p>Sözlemi sesli bot bilen ýazyşyp öwreniň</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "sentence-test");
            }}
            to="/lesson/sentence/test/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "sentence-test" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 6</h5>
              <p>Öwrenen sözlemiňizi bot bilen ýazyşyp test ediň</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "learn-dialog");
            }}
            to="/lesson/test/"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "learn-dialog" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 7</h5>
              <p>Dialog öwreniň</p>
            </div>
          </Link>
          <Link
            onClick={(e) => {
              handleClick(e, "dialog-test");
            }}
            to="/lesson/test/text"
            className={
              "filterDiscussions  single all unread " +
              (props.menu === "dialog-test" && "active")
            }
            id="list-chat-list5"
            data-toggle="list"
            role="tab"
          >
            <div className="data">
              <h5>Ädim 8</h5>
              <p>Teks okaň we terjime ediň</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
