import React, { useState } from "react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useNavigate, useParams } from "react-router-dom";
import { CircleLoader } from "react-spinners";

export function ProductAdminCard(props) {
  const [opened, setOpened] = React.useState(false);
  const [current, setCurrent] = React.useState(1);
  const [favorite, setFavorite] = React.useState(props.item.liked);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const navigate = useNavigate();

  const open = () => {
    setOpened(!opened);
  };

  const jump = (index) => {
    setCurrent(index);
  };

  const change = (value) => {
    var number = value ? 1 : -1;
    current + number > 3
      ? jump(1)
      : current + number < 1
      ? jump(3)
      : setCurrent(current + number);
  };

  const editButton = () => {
    navigate("/market/mine/edit/" + props.item.code);
  };

  const deleteButton = () => {
    const headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
    const data = { product_id: props.item.id };
    const choice = window.confirm(
      "Bu haryty hakykyatdan hem pozmak isleyarsinizmi?"
    );
    if (choice) {
      axios
        .delete("https://peydalan.com.tm/api/product/admin", {
          headers: headers,
          data: data,
        })
        .then((response) => {
          props.refresh();
        });
    }
  };

  return (
    <div className="text-content-frame">
      {/* <img src={"https://peydalan.com.tm" + props.item.image_1} /> */}
      {!opened && props.item.image_1 && <img src={props.item.image_1} />}
      <div style={{ display: opened ? "block" : "none" }}>
        <div className="slideshow-container">
          {current == 1 && (
            <div>
              <div className="numbertext">1 / 3</div>
              <img src={props.item.image_1} className="slide-img" />
            </div>
          )}
          {current == 2 && (
            <div>
              <div className="numbertext">2 / 3</div>
              <img
                src={props.item.image_2 ? props.item.image_2 : props.item.image}
                className="img"
              />
            </div>
          )}
          {current == 3 && (
            <div>
              <div className="numbertext">3 / 3</div>
              <img
                src={props.item.image_3 ? props.item.image_3 : props.item.image}
                className="img"
              />
            </div>
          )}
          <a
            className="prev"
            onClick={() => {
              change(false);
            }}
          >
            &#10094;
          </a>
          <a
            className="next"
            onClick={() => {
              change(true);
            }}
          >
            &#10095;
          </a>
        </div>
      </div>
      <table className="product-table">
        <tr className="product-row">
          <td className="product-title">Ady:</td>
          <td className="product-info">{props.item.name}</td>
        </tr>
        <tr className="product-row">
          <td className="product-title product-price">
            Bahasy:{"  "}
            {props.item.price}
          </td>
          <td className="product-info product-price">
            Kody:{"  "}
            {props.item.code}
          </td>
        </tr>

        {opened && (
          <tr className="product-row">
            <td className="product-title">Kategoriýasy:</td>
            <td className="product-info">{props.item.market.category.name}</td>
          </tr>
        )}
      </table>
      {opened && <div className="product-about">{props.item.about}</div>}
      {opened && (
        <div className="product-market-info">
          {props.item.market.name}
          {"  "}
          {props.item.market.phone_number}
          {"  "}({props.item.market.code})
        </div>
      )}
      <button
        className="text-button"
        onClick={() => {
          open();
        }}
      >
        {!opened ? "Doly görkez" : "Gizle"}
      </button>
      <button className="text-button" onClick={editButton}>
        Edit
      </button>
      <button className="text-button" onClick={deleteButton}>
        Delete
      </button>
    </div>
  );
}

export function ProductAddForm(props) {
  const [image1, setImage1] = React.useState();
  const [image2, setImage2] = React.useState();
  const [image3, setImage3] = React.useState();
  const [preview1, setPreview1] = React.useState();
  const [preview2, setPreview2] = React.useState();
  const [preview3, setPreview3] = React.useState();
  const [name, setName] = React.useState();
  const [price, setPrice] = React.useState();
  const [about, setAbout] = React.useState();
  const [code, setCode] = React.useState();
  const [errors, setErrors] = React.useState();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [popupOpened, setPopupOpened] = React.useState(false);
  const [options, setOptions] = React.useState();
  const [market, setMarket] = React.useState();
  const [message, setMessage] = React.useState();

  const onFileChange1 = (event) => {
    // Update the state
    const file = event.target.files[0];

    if (file && file.size > 300 * 1024) {
      // 150 kB in bytes
      event.target.value = null; // Reset the input value to clear the selection
      setPreview1("");
      setMessage("Saýlanan faýl 300Kb-dan uly. kiçi faýl saýlaň");
      setPopupOpened(true);
    } else {
      setImage1(event.target.files[0]);

      const objectUrl = URL.createObjectURL(event.target.files[0]);
      setPreview1(objectUrl);
    }
  };

  const onFileChange2 = (event) => {
    // Update the state
    const file = event.target.files[0];
    if (file && file.size > 300 * 1024) {
      // 150 kB in bytes
      event.target.value = null; // Reset the input value to clear the selection
      setPreview2("");
      setMessage("Saýlanan faýl 300Kb-dan uly. kiçi faýl saýlaň");
      setPopupOpened(true);
    } else {
      setImage2(event.target.files[0]);

      const objectUrl = URL.createObjectURL(event.target.files[0]);
      setPreview2(objectUrl);
    }
  };

  const onFileChange3 = (event) => {
    const file = event.target.files[0];
    if (file && file.size > 300 * 1024) {
      // 150 kB in bytes
      event.target.value = null; // Reset the input value to clear the selection
      setPreview3("");
      setMessage("Saýlanan faýl 300Kb-dan uly. kiçi faýl saýlaň");
      setPopupOpened(true);
    } else {
      // Update the state
      setImage3(event.target.files[0]);

      const objectUrl = URL.createObjectURL(event.target.files[0]);
      setPreview3(objectUrl);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleAboutChange = (event) => {
    setAbout(event.target.value);
  };

  const handleCodeChange = (event) => {
    if (event.target.value < 100 && event.target.value >= 0) {
      setCode(event.target.value);
    }
  };

  const addProduct = () => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    const formData = new FormData();
    formData.append("image_1", image1);
    formData.append("image_2", image2);
    formData.append("image_3", image3);
    formData.append("name", name);
    formData.append("price", price);
    formData.append("about", about);
    formData.append("code", code);

    setLoading(true);

    axios
      .post("https://peydalan.com.tm/api/product/admin", formData, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        navigate("/market/mine");
      })
      .catch((error) => {
        if (error.response.status == 403) {
          setLoading(false);
          setErrors(error.response.data);
        } else {
          setLoading(false);
          setPopupOpened(true);
          setMessage(error);
        }
      });
  };

  React.useEffect(() => {
    const headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
    var codes;
    axios
      .get("https://peydalan.com.tm/api/get-product-codes", {
        headers: headers,
      })
      .then((response) => {
        codes = response.data.body;

        var temp_options;
        temp_options = [
          <option key={100} value={null}>
            Kod Sayla
          </option>,
        ];

        for (let i = 1; i <= 99; i++) {
          temp_options.push(
            <option key={i} value={i} disabled={codes.includes(i)}>
              {i}
            </option>
          );
        }

        setOptions(temp_options);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get("https://peydalan.com.tm/api/get-category-warning", {
        headers: headers,
      })
      .then((response) => {
        setMessage([response.data.warning]);
        setPopupOpened(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">{message}</div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                OK
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      {loading ? (
        <CircleLoader className="loader" color="#33A2F4" size={90} />
      ) : (
        <div className="admin-form">
          <div
            className={
              "admin-form-element " +
              (errors && errors.image_1 && "admin-form-element-error")
            }
          >
            {preview1 && <img src={preview1} />}
            <input type="file" onChange={onFileChange1} />
            <span className="admin-form-error-span">
              {errors && errors.image_1}
            </span>
          </div>
          <div
            className={
              "admin-form-element " +
              (errors && errors.image_2 && "admin-form-element-error")
            }
          >
            {preview2 && <img src={preview2} />}
            <input type="file" onChange={onFileChange2} />
            <span className="admin-form-error-span">
              {errors && errors.image_2}
            </span>
          </div>
          <div
            className={
              "admin-form-element " +
              (errors && errors.image_3 && "admin-form-element-error")
            }
          >
            {preview3 && <img src={preview3} />}
            <input type="file" onChange={onFileChange3} />
            <span className="admin-form-error-span">
              {errors && errors.image_3}
            </span>
          </div>
          <div className="admin-form-div">
            <span>Ady:</span>
            <input onChange={handleNameChange} />
          </div>
          <div className="admin-form-div">
            <span>Bahasy:</span>
            <input type={"number"} onChange={handlePriceChange} />
          </div>
          <div className="admin-form-div">
            <span>Hakynda:</span>
            <textarea
              onChange={handleAboutChange}
              rows={10}
              value={about}
            ></textarea>
          </div>
          <div
            className={
              "admin-form-div " +
              (errors && errors.code && "admin-form-element-error")
            }
          >
            <span>Kody:</span>
            <select onChange={handleCodeChange} value={code}>
              {options}
            </select>
            {/* <input
              type={"number"}
              maxLength={2}
              onChange={handleCodeChange}
              value={code}
            /> */}
            <span className="admin-form-error-span">
              {errors && errors.code}
            </span>
          </div>
          <button className="admin-form-button" onClick={addProduct}>
            Add
          </button>
        </div>
      )}
    </div>
  );
}

export function ProductEditForm(props) {
  const { code } = useParams();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [image1, setImage1] = React.useState();
  const [image2, setImage2] = React.useState();
  const [image3, setImage3] = React.useState();
  const [preview1, setPreview1] = React.useState();
  const [preview2, setPreview2] = React.useState();
  const [preview3, setPreview3] = React.useState();
  const [options, setOptions] = React.useState();
  const gotOptions = React.useRef(false);

  React.useEffect(() => {
    const headers = {
      Authorization: "Token " + localStorage.getItem("token"),
    };
    axios
      .get("https://peydalan.com.tm/api/product/check?code=" + code, {
        headers: headers,
      })
      .then((response) => {
        setData(response.data);
        setPreview1(response.data.image_1);
        setPreview2(response.data.image_2);
        setPreview3(response.data.image_3);
      })
      .catch((error) => {
        navigate("/market/mine");
      });
  }, []);

  React.useEffect(() => {
    if (data && data.code && !gotOptions.current) {
      const headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
      var codes;
      axios
        .get("https://peydalan.com.tm/api/get-product-codes", {
          headers: headers,
        })
        .then((response) => {
          codes = response.data.body;

          var product_code = parseInt(String(data.code).slice(-2), 10);
          setData({ ...data, ["code"]: product_code });
          var temp_options;
          temp_options = [
            <option key={100} value={null}>
              Kod Sayla
            </option>,
          ];

          for (let i = 1; i <= 99; i++) {
            temp_options.push(
              <option
                key={i}
                value={i}
                disabled={codes.includes(i) && i != product_code}
              >
                {i}
              </option>
            );
          }

          setOptions(temp_options);
          gotOptions.current = true;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [data]);

  const onFileChange1 = (event) => {
    // Update the state
    setImage1(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview1(objectUrl);
  };

  const onFileChange2 = (event) => {
    // Update the state
    setImage2(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview2(objectUrl);
  };

  const onFileChange3 = (event) => {
    // Update the state
    setImage3(event.target.files[0]);

    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview3(objectUrl);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const saveProduct = () => {
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: "Token " + token,
    };

    const formData = new FormData();
    formData.append("image_1", image1);
    formData.append("image_2", image2);
    formData.append("image_3", image3);
    formData.append("id", data.id);
    formData.append("name", data.name);
    formData.append("price", data.price);
    formData.append("about", data.about);
    formData.append("code", data.code);

    axios
      .patch("https://peydalan.com.tm/api/product/admin", formData, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        navigate("/market/mine");
      });
  };

  return (
    <div className="admin-form">
      {data && (
        <div>
          <div className="admin-form-element">
            {preview1 && <img src={preview1} />}
            <input type="file" onChange={onFileChange1} />
          </div>
          <div className="admin-form-element">
            {preview2 && <img src={preview2} />}
            <input type="file" onChange={onFileChange2} />
          </div>
          <div className="admin-form-element">
            {preview3 && <img src={preview3} />}
            <input type="file" onChange={onFileChange3} />
          </div>
          <div className="admin-form-div">
            <span>Ady:</span>
            <input name="name" onChange={handleFormChange} value={data.name} />
          </div>
          <div className="admin-form-div">
            <span>Bahasy:</span>
            <input
              type={"number"}
              name="price"
              onChange={handleFormChange}
              value={data.price}
            />
          </div>
          <div className="admin-form-div">
            <span>Hakynda:</span>
            <textarea
              name="about"
              onChange={handleFormChange}
              value={data.about}
              rows={10}
            ></textarea>
          </div>
          <div className="admin-form-div">
            <span>Kody:</span>
            <select
              name="code"
              onChange={handleFormChange}
              value={data.code}
              defaultValue={data.code}
            >
              {options}
            </select>
          </div>
          <button className="admin-form-button" onClick={saveProduct}>
            Save
          </button>
        </div>
      )}
    </div>
  );
}
