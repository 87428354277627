import React from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TextCard from "./cards/TextCard";
import TestTextCard from "./cards/TestTextCard";
import MessageCard from "./cards/MessageCard";
import TestCard from "./cards/TestCard";
import Popup from "reactjs-popup";

const slugify = (text) => {
  return (
    text
      .toString()
      // .normalize("NFKD")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "")
      .replace(/[.!?,]/g, "")
      .replace(/\-\-+/g, "")
      .replace(/;/g, "")
      .replace(/'/g, "")
      .replace(/"/g, "")
      .replace(/,/g, "")
  );
};

function slugifyChinese(string) {
  // Create a regular expression that matches all non-Chinese characters.
  const pattern = /[^\u4e00-\u9fa5]/g;

  // Replace all non-Chinese characters with an empty string.
  const result = String(pattern).replace(string, "");

  // Return the slugified string.
  return result;
}

export function Test(props) {
  const [dialog, setDialog] = React.useState([{}]);

  const [isTyping, setIsTyping] = React.useState(false);

  const [selected, setSelected] = React.useState();

  const [answers, setAnswers] = React.useState([]);

  const [questions, setQuestions] = React.useState(props.words); // create a state for questions
  const [correctAnswer, setCorrectAnswer] = React.useState({});
  const messagesEndRef = React.useRef(null);
  const welcomeMessage = React.useRef("");

  const sendError = (text) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        message: "☹️ \n Ýalňyşdyňyz!!! Dogrusy:  " + text,
        me: false,
        error: true,
      },
    ]);
  };

  const sendInfo = (text, audio = null) => {
    setDialog((dialogs) => [
      ...dialogs,
      {
        audio: audio,
        message: text,
        me: false,
        info: true,
      },
    ]);
  };

  const selectedSet = (text) => {
    setSelected(text);
  };

  function getRandomAnswers(correctAnswer) {
    // Create a copy of the state array
    let copy = [...props.dialogs];
    // Remove the given element from the copy
    let index = copy.indexOf(correctAnswer);
    if (index > -1) {
      copy.splice(index, 1);
    }
    // Shuffle the copy array
    for (let i = copy.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [copy[i], copy[j]] = [copy[j], copy[i]];
    }
    // Return the first two elements of the copy array
    return copy.slice(0, 2);
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const sendQuestion = () => {
    if (questions) {
      if (questions.length > 0) {
        // check if there are any questions left
        const index = Math.floor(Math.random() * questions.length); // get a random index
        const question = questions[index];
        var falseAnswers = getRandomAnswers(question);
        falseAnswers = [...falseAnswers, question];

        const answers = shuffleArray(falseAnswers);

        setIsTyping(false);
        setCorrectAnswer(question);
        // get the question at that index
        setDialog((dialogs) => [
          ...dialogs,
          {
            question: true,
            audio: question.audio_question_another,
            message: question.question_another,
            additional1: question.additional1,
            answer1: answers[0].answer_another,
            answer1_additional2: answers[0].additional2,
            audio1: answers[0].audio_answer_another,
            answer2: answers[1].answer_another,
            answer2_additional2: answers[1].additional2,
            audio2: answers[1].audio_answer_another,
            answer3: answers[2].answer_another,
            answer3_additional2: answers[2].additional2,
            audio3: answers[2].audio_answer_another,
            disabled: false,
            me: false,
          },
        ]); // add the question to the dialog
      }
    }
  };

  React.useEffect(() => {
    if (questions) {
      if (questions.length === 0) {
        setQuestions(props.dialogs);
      }
      sendQuestion();
    }
  }, [questions]);

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
  }, []);

  React.useEffect(() => {
    if (props.lesson) {
      var language = "";
      if (props.lesson.category === "english") {
        language = "Iňlis dili";
      } else if (props.lesson.category === "russian") {
        language = "Rus dili";
      } else if (props.lesson.category === "chinese") {
        language = "Hytaý dili";
      }
      welcomeMessage.current =
        "Salam! " +
        language +
        " öwrenmegiň 7-nji ädiminiň türgenleşigine hoş geldiňiz! Bu sapakda öwrediş usulylymyz 4-5-6-njy adimde öwren sözlemlermiziň jogabyny tapmakdan ybarat boljak, Ýagny bu usul sizi gepleşige taýýarlar. Ulanylşy adatça ýamkak arkaly bolman jogap saýlamak arkaly bolar, biz siziň jogaplarňyzy barlap okuw netijeleriňiziň jogabyny açarys. Good Luck!";
    }
  }, [props.lesson]);

  React.useEffect(() => {
    if (!selected) {
      return;
    }

    setSelected(false);

    const newElements = dialog.map((element) => {
      // Use the spread operator to copy the existing properties of the element
      // Then overwrite the disabled property with the value of the question property
      return { ...element, disabled: element.question };
    });

    // Then use the setState method to update the state with the new array
    setDialog(newElements);
    if (selected === correctAnswer.answer_another) {
      setTimeout(() => {
        sendInfo("Dogry, Berekella");
      }, 2500);

      setTimeout(() => {
        setQuestions(questions.filter((q, i) => q.id !== correctAnswer.id)); // remove the question from the questions array
      }, 4000);
    } else {
      setTimeout(() => {
        sendError(correctAnswer.answer_another);
      }, 1000);
      setTimeout(() => {
        setQuestions(questions.filter((q, i) => q.id !== correctAnswer.id)); // remove the question from the questions array
      }, 2500);
    }
  }, [selected]);

  React.useEffect(() => {
    if (!props.ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: props.ad.image,
          me: false,
          ad: true,
          view: props.ad.view,
          id: props.ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [props.ad]);

  React.useEffect(() => {
    if (props.dialogs) {
      if (props.dialogs.length !== 0) {
        setTimeout(() => {
          setQuestions(props.dialogs);
        }, 4500);
        // Set the state with the data
      }
    }
  }, [props.dialogs]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              item.question ? (
                <TestCard
                  key={index}
                  selectedSet={selectedSet}
                  message={item.message}
                  additional1={item.additional1}
                  audio={item.audio}
                  answer1={item.answer1}
                  answer1_additional2={item.answer1_additional2}
                  audio1={item.audio1}
                  answer2={item.answer2}
                  answer2_additional2={item.answer2_additional2}
                  audio2={item.audio2}
                  answer3={item.answer3}
                  answer3_additional2={item.answer3_additional2}
                  audio3={item.audio3}
                  disabled={item.disabled && item.disabled}
                />
              ) : (
                <MessageCard
                  key={index}
                  ad={item.ad}
                  view={item.view}
                  sponsorId={item.id}
                  message={item.message}
                  audio={item.audio}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                />
              )
            )}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                disabled
                className="form-control"
                placeholder="Doňdurylan..."
                onFocus={props.inputFocusedSet}
                onBlur={props.inputFocusedSet}
                rows="0"
              ></textarea>
              <button type="submit" className="btn send">
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TestText(props) {
  const [ad, setAd] = React.useState([]);
  const [dialog, setDialog] = React.useState([{}]);
  const [lesson, setLesson] = React.useState();
  const [isTyping, setIsTyping] = React.useState(false);
  const messagesEndRef = React.useRef(null);
  const text_store = React.useRef(false);
  const texts = React.useRef(false);
  const welcomeMessage = React.useRef("");

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/lesson?id=" + props.lessonId)
      .then((response) => {
        // set the array state to the response data
        setLesson(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/ad")
      .then((response) => {
        // set the array state to the response data
        setAd(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
    axios
      .get("https://peydalan.com.tm/api/text?lesson=" + props.lessonId)
      .then((response) => {
        // set the array state to the response data
        texts.current = response.data;
        text_store.current = response.data;
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [props.lessonId]);

  const sendText = () => {
    if (texts.current.length > 0) {
      const text = texts.current[0];
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          question: true,
          message: text.another_text,
          button: "Terjimesini görkez",
          me: false,
          onclick: () => {
            setTimeout(() => {
              showTurkmen();
            }, 1500);
          },
        },
      ]); // add the question to the dialog
    } else {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: "Bu sapak tamamlandy. Indiki sapagymyza garashyarys",
          me: false,
          info: true,
        },
      ]);
    }
  };

  const removeElement = (array, otherArray) => {
    array.current = array.current.slice(1);
  };

  const showTurkmen = () => {
    if (texts.current.length > 0) {
      const text = texts.current[0];
      removeElement(texts, text_store);
      setIsTyping(false);
      // get the question at that index
      setDialog((dialogs) => [
        ...dialogs,
        {
          question: true,
          message: text.turkmen,
          button: "Indiki",
          me: false,
          onclick: () => {
            setTimeout(() => {
              sendText();
            }, 1500);
          },
        },
      ]); // add the question to the dialog
    }
  };

  React.useEffect(() => {
    setIsTyping(true);
    setTimeout(() => {
      setDialog((dialogs) => [
        ...dialogs,
        {
          message: welcomeMessage.current,
          me: false,
        },
      ]);
    }, 3500);
    setTimeout(() => {
      sendText();
    }, 5000);
  }, []);

  React.useEffect(() => {
    if (lesson) {
      var language = "";
      if (lesson.category === "english") {
        language = "Iňlis dili";
      } else if (lesson.category === "russian") {
        language = "Rus dili";
      } else if (lesson.category === "chinese") {
        language = "Hytaý dili";
      }
      welcomeMessage.current =
        "Salam! " +
        language +
        " öwrenmegiň 8-nji ädiminiň türgenleşigine dälde siziň türgenleşigipşňiziň netijesini bilmäge hoş geldiňiz! Ýagny bu sahypamyzda öwrenen söz we sözlemleriňizden düzilen tekist görkezler, siz bu tekisdi okap ýa-da diňläp terjimäňizi pikirlenmeli, soňra bu terjime pikiriňiz dogrymydygyny bilmek üçin terjimesini ýaz düwmä basmaly we öz netijäňizi deňeşdirmeli. Hemme jogaplarňyz deň gelse siz bu sapagymyzy şowly tamamladygyňyz bolar.";
    }
  }, [lesson]);

  React.useEffect(() => {
    if (!ad) {
      return;
    }
    setTimeout(() => {
      setDialog((dialog) => [
        {
          message: ad.image,
          me: false,
          ad: true,
          view: ad.view,
          id: ad.id,
        },
        ...dialog.slice(1),
      ]);
    }, 500);
  }, [ad]);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // scroll to the ref element
    document.getElementById("toBottom").scrollTop =
      document.getElementById("toBottom").scrollHeight;
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [dialog]);

  return (
    <div className="chat" id="chat1">
      <div
        className="content"
        id="content"
        style={{
          height:
            props.windowSize.width < 942 ? props.inputFocused && "92%" : "80%",
        }}
      >
        <div className="container">
          <div className="col-md-12" id="toBottom">
            {dialog.map((item, index) =>
              item.question ? (
                <TestTextCard
                  key={index}
                  button={item.button}
                  message={item.message}
                  audio={item.audio}
                  onClick={item.onclick}
                />
              ) : (
                <MessageCard
                  key={index}
                  ad={item.ad}
                  view={item.view}
                  sponsorId={item.id}
                  message={item.message}
                  audio={item.audio}
                  me={item.me && "true"}
                  error={item.error && "true"}
                  info={item.info && "true"}
                />
              )
            )}
            <div ref={messagesEndRef}></div>
            {isTyping && (
              <div className="message">
                <div className="text-main">
                  <div className="text-group">
                    <div className="text typing">
                      <div className="wave">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12">
          <div className="bottom">
            <form className="position-relative w-100">
              <textarea
                disabled
                className="form-control"
                placeholder="Doňdurylan..."
                rows="0"
              ></textarea>
              <button type="submit" className="btn send">
                <i className="material-icons">send</i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ChooseLesson(props) {
  const [lessons, setLessons] = React.useState([]);
  const [lang, setLang] = React.useState("english");
  const [selected, setSelected] = React.useState(0);
  const [popupOpened, setPopupOpened] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    var headers = {};
    if (localStorage.getItem("token")) {
      headers = {
        Authorization: "Token " + localStorage.getItem("token"),
      };
    }

    // make an API call using axios
    axios
      .get("https://peydalan.com.tm/api/lesson?category=" + lang, {
        headers: headers,
      })
      .then((response) => {
        // set the array state to the response data
        setSelected(response.data[0].id);
        setLessons(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [lang]);

  const handleContinue = () => {
    if (selected === 0) {
      alert("Sapak Saylan");
      return; // stop the function execution
    }

    const lesson = lessons.filter((obj) => obj.id == selected);
    if (lesson[0].premium) {
      if (localStorage.getItem("token")) {
        const token = localStorage.getItem("token");

        const headers = {
          Authorization: "Token " + token,
        };

        axios
          .get("https://peydalan.com.tm/api/get-user", { headers: headers })
          .then((response) => {
            if (response.data.isPremium) {
              props.setLessonId(selected);
              navigate("/lesson");
            } else {
              setPopupOpened(true);
            }
            // Handle the response, such as showing a success message
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setPopupOpened(true);
      }
    } else {
      props.setLessonId(selected);
      navigate("/lesson");
    }
  };

  const handleLesson = (event) => {
    setSelected(event.target.value);
  };

  const handleSelect = (event) => {
    setLang(event.target.value);
  };

  return (
    <div className="test-frame lesson-frame">
      <Popup
        open={popupOpened}
        modal
        closeOnDocumentClick={true}
        onClose={() => {
          setPopupOpened(false);
        }}
        nested
      >
        {(close) => (
          <div className="modal">
            <div className="header"> Maglumat </div>
            <div className="content">
              Bu sapak premium ulanyjylar üçin <br />
              Premium ulanyjy bolmak üçin habarlaşmaly. <br />
              +99364197532
              {/* gönümel  geçmek */}
            </div>
            <div className="actions">
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                Tanyşyldy
                {/* duşünişmek */}
              </button>
            </div>

            <a className="close" onClick={close}>
              &times;
            </a>
          </div>
        )}
      </Popup>
      <h4 className="lesson-title">Bilýän diliňizi saýlaň:</h4>
      <select className="select chooseLessonSelect">
        <option value="turkmen">Türkmen dili</option>
      </select>
      <h4 className="lesson-title">Öwrenjek diliňizi saýlaň:</h4>
      <select
        className="select chooseLessonSelect"
        onChange={(event) => {
          handleSelect(event);
        }}
      >
        <option value="english">Iňlis dili</option>
        <option value="russian">Rus dili</option>
        <option value="chinese">Hytaý dili</option>
      </select>
      <h4 className="lesson-title">Sapagy saýlaň:</h4>
      <select
        className="select chooseLessonSelect"
        id="lesson"
        onChange={(event) => {
          handleLesson(event);
        }}
        defaultValue={selected}
      >
        {lessons.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name} - {item.word_count} sözden/{item.progress}
          </option>
        ))}
      </select>
      <button
        className="test-button"
        onClick={() => {
          handleContinue();
        }}
      >
        Mazmunyna geç
      </button>{" "}
      {lang === "english" && (
        <div className="lesson-info">
          Iňlis diline girişmek üçin ilki iňlis dili barada düşünje almagy dogry
          hasaplaýaryn, bu diýýan düşünjämiz has düşnükli bolar ýaly Türkmen
          dili bilen Iňlis dilini deňeşdirip pikirlenişeliň. Mysal üçin: Türkmen
          dilinde sesi hata geçirmek üçin harplar ulanylar, iňlis dili hem şolar
          ýaly sesi harplar arkaly hata geçiryärler, ýöne bir tapawut, çekimli
          harplaryň täsiri barada durup geçeliň. Türkmen dilinde çekimli harplar
          sözüň manysyny üýtgedip bilyär. Mysal üçin pil (jandar), pi:l (gural),
          iňlis dilinde welin çekimli harplar sözüň manysyny üýtgetmän harpyň
          sesini üýtgedýär. Mysal getireliň: “a” harpyň sesiniň üýtgeýişi
          (game=geým)-oýun, (faсt=fäkt)-maglumat, (star=star)-ýyldyz, çekimli
          harplaryň täsiri barada aşakda belläliň. a- (game=geým)-oýun,
          (faсt=fäkt)-maglumat, (star=star)-ýyldyz, (stare=steir)-seredip
          durmak, o- (pole=poul)-meýdan, (hot=hot)- yssy,gyzgyn;
          (sport=sport)-sport, (more=mo:r)-köpräk, e- (pete=pi:t)-Pete (adam
          ady), (sent=sent)-ugratmak, (team=ti:m)-topar, (here=hier)-şu ýerde.
          i- (fine=faýn)-gowy, (win=win)-ýeňmek, (first=först)-birinji,
          (tired=taired)-ýadow, u- (tune=týun)-sazlama, (club=klab)-topar,
          (turn-törn)- öwrülmek, (cure=kýuer)-bejermek; başgada käbir harplar
          goşa ýazylyp, sesleri üýtgedip hem bilýär. Eger “o” harpy (biziň
          sesimizde öw sesini berýär) iki gezek yzly yzyna ýazylsa oo=u sesini
          beryär. Mysal üçin sözlerde göreliň: book (buk), look (luk), school
          (skul), ýenede goşalanyp gelende üýtgeýän harplar kän. Bular barada
          aşakdaky tablisa ünsümizi çekeli oo=u (pool=pul)-meydança, ee=i
          (see=si)-görmek, ea=i (tea=ti)- çaý. Ýöne gönümel okalýan ýagdaýlary
          hem bar. Şuňa mysal: (break=break)-döwmek, ch=ç (china=çaýna)-hytaý,
          sh=ş (shop=şop)-dükan, ck=k (back=bäk)-arka, ph=f (photo=foto)-surat,
          th=s (thank=sank)-minnetdar, wh=w (when=wen)-haçan. Eger wh o
          harpyndan öň gelse, “h” diylip okalýar, mysal üçin: who=hu (kim), wr=r
          (write=rayt)-ýazmak.
        </div>
      )}
      {lang === "chinese" && (
        <div className="lesson-info">
          <p>
            Hytaý dilini öwrenmäge başlamak üçin Türkmen dili bilen hytaý
            diliniň arasyndaky tapawutlara seredip geçeliň. Biz sesleri hata
            geçirmek üçin harplary ulanýarys. Mysal üçin: (Türkmenistan) hytaý
            dilinde sesi hata geçirmek üçin bogunlar ulanylýar. Muňa mysal: (
            土库曼斯坦) bu sözüň bogunlaryny harplara bölsek 土tu, 库ku, 曼man,
            斯si, 坦tan bu ýerde bogunlarynyň sesleri ulanylan, bu ýagdaýy “has
            atlarda” ulanylýar. Mysal üçin ýurduň atlary ýada adam atlary, zawod
            atlary ýaly ýagdaýlarda ulanylýar, ýöne bir zat galdy, bogunlary
            goşup ýazalyň (tu,ku,man,si,tan) Näme üçin seslerini biziňkä meňzeş
            ýazyp bolmady??? Sebäbini giňişleýin düşündireýin: biziň Türkmen
            dilimizdäki 30 harp bilen ähli sözlermizi bolşy ýaly ýazyp bilýäris,
            hytaý dilinde harp ulanylman bogun ulanylýar diýip geçipdik. Bary
            ýogy 600 bogun, bu 600 bogun bilen ähli zadyň sesini bolşy ýaly
            ýazyp bolmaýar, mysal: Türkmenistanyň “türk” sesine derek “tu”
            sesini ulandy, sebabi muňa ýakyn başga bogunlary ýok, geliň indi t
            harpyndan gelýän bogunlaryna seredeliň: ta, te, ti, tu, tai, tei,
            tui, tao, tuo, tie, tan, tun, tang, teng, ting, tong, tiao, tian,
            tuan, tuo. “T” harpyndan başlaýan bogunlary şulardan ybarat, başga t
            harpyndan gelýän ses bogun ýok, şeýlelik bilen ähli sözleri hytaý
            dilinde bolşy ýaly sesde ýazyp bolmaýar, ýöne bu gurluşyň artykmaç
            taraplary hem bar, bular barada bellesek: esasy gepleşiklerde her
            bogunyň terjimesi bar, mysal: ulag-车(che) ýada internet diýeli
            上网(shang wang) gönümel terjime etsek (ýokarky tor), indi men 车
            sözüne gabat gelemde okalyş sesi ýadyma düşmesede, ýada okalyş
            sesini doly bilmänimde-de şu harplaryny öz dilimde ulag diyip okap
            bilerin, ýa-da上网 men muny (ýokarky tor) diýip ]z dilimde okap
            bilýärin, ýokarky tor diýilende ilkinji internet gözegiňe gelýär,
            geliň hytaý diliniň taryhy barada durup geçeliň, hytaý ýurdynyň
            içinde dürli dilli milletler kän bolan, bular bilen ses arkaly
            duşünişmek mümkin bolmadyk, sebabi iki milletiň dilleri meňzeş
            bolmadyk, bu ýagdaýda harpa derek şekil ulanylmasy ygtybarly bolan
            we ýazylan şekilli hatlary her millet öz dilinde okap bilmäge
            mümkinçilik bolupdyr, ýene mysal getireli “我来”men gidýän, hytaý
            dilinde näme ses berýänini bilmesem hem manysyna öz dilimde
            duşündim, ýöne häzirki zaman tehnologiýalarda bu şekil hatlaryny
            çalt ýazmak üçin hytaý diliniň sesini bilmeli bolýar, mysal munça
            şekil hatlary klawiatura ýerleşdirip bolmaýanlygy sebäpli
            aňsatlaşdyryp sesini latyn harplarynda ýazsaň, şekilli harplary
            ekrana çykarýan programmalar döredilen, bu aydýan programmamyzy
            nädip kompýuterimize ýada el telefonlarymyza nädip gurnamalydygy
            barada wideo düşündirşimizde doly ýagdaýda düşündirip geçeris. Ýene
            köp beriljek soraglaryň biri bolan: ýokarda belläp geçişimiz ýaly
            600 bogun bar. Ýöne 600 bogun bilen nädip milyardlarça sözleri
            düzýärler? Muňa düşündiriş geçmek üçin bir söz alalyň. Mysal üçin
            (si) boguny alalyň “si” bogundan emele gelýän sözlere
            seredeli:（dört-四
            si）（umyt-思si）（ýüplük-丝si）（ölüm-死si）başgada kän…… Bir sesde
            näçe söz bar, bulary nähili tanajak diýilse, öz dilimizden mysal
            getirjek oňa mysal: (tut) tut diýip bag hem bar, bir zady tut diýip
            işlik hem bar, ýöne bizde bir ses esasy iki many berýär. Üç many
            berýän söz kän ýok. Hytaý dilinde bolsa bir ses gaty köp many
            berýär. Gaýta bir sesiň bir many berýäni ýok, bulary tanamak üçin
            diňe sesiň aýdylyş tonlaryna üns bermeli bolýar. “Ton” bolanda
            “tu:t” diyenimizde bagyň adyny berdi, “tut” diýenimizde welin bir
            zady tutmak manyny berdi. Hytaý diliniň sözlerini ýat tutmak kyn
            görünýärmi? Ol asla beýle däl! Gaýta aňsat, sözleri ýat tutmakda
            artykmaç taraplaryna seredeli. Mysal（dört-四si）sözüni ýat tutduk
            welin（umyt-思si）,（ýüplük-丝si),（ölüm-死si）sesi birmeňzeş sözler
            gapdaly bilen öwrenmesizden ýadyňyza geler, sesleriň tony diňe ýüzbe
            ýüz gepleşiklerde has dogry aýdylmagyny talap edýär. Internet ýa sms
            ýazylanda klawiaturada ses “tony” ulanylmaýar.
          </p>
        </div>
      )}
      {lang === "russian" && (
        <div className="lesson-info">
          <p>
            Haýsydyr bir dili öwrenmäge başlamak üçin ilki öz gürleýän diliniñ
            harplarynyň, sözleriniň we dil sistemasynyň tapawutlyklaryna seredip
            geçmeli, muňa mysal: 1) Rus diliniň türkmen diline görä harplarynyň
            şekilleri örän tapawutly we käbir harplarynyň sesleri hem tapawutly,
            Я=ýa; Ю=ýu; е=ýe; ъ=gatylyk belgi; Ь=ýumşaklyk belgi; Ц=s(ýitiräk
            aýtmaly) щ=ş(ýitiräk aýtmaly). Я.ю.е bu harplary йа ýa-da йо diýip
            goşa harp arkaly hem ýazyp bolar emma bu goşa sesler rus dilinde
            örän köp ulanylýanlygyndanmy, goşa sesi bir harpa ýerleşdirilipdir.
            “Ъ”, “Ь” indi ýumşaklyk we gatylyk harplarynyň täsirini öz türkmen
            dilimizde ulanmaga synanyşyp göreliň. Mysal üçin: pilь ýa-da pilъ
            diýeli, biz bu ýagdaýda sözleriň biriniň (jandar Pil)-digini
            beýlekisiniň welin (gural Pil)-digini aňdyk. Bolmasa diňe Pil
            ýazgysy ýeke ýazylan bolsa manysy jedelli boljak ýaly. Biz bir bada
            aňsat bolar ýaly şeýle düşünşeliň, emma bu ýagdaý biraz başgaça. Rus
            dilinde mysal alalyň 1. Съел = iýmek 2. Сел= otyrdy 3. Сель = sil,
            1. Пенка = köpürjik 2. Пенька = ýüpüň bir görnüşi, 1. Кол = çöp 2.
            Коль = ýöne. “Ц”, “щ” harplary Türkmen dilinde ulanylmaýar. Türkmen
            diliniň harplaryna garanyñda rus dilinde ýok harplar: “Ö. Ä. Ň”. Bu
            sesler rus dilinde ulanylmaýar. Ýene-de rus dilinde “женский род,
            мужской род , средний род” diyip bar. Bularyň tapawutlaryny sözüň iň
            soňky harpyndan tanap bolýar. Mysal üçin: стол (мужской род),
            книга(женский род), очки(средний род ). Ýapyk çekimli “мужской род”
            degişli (a, и), açyk çekimliler bilen gutarsa “женский род” degişli,
            soňy (e) bilen gutarsa “средний род” bolýar. Bu ýagdaýyň düýp manysy
            gepleşikleriň owadan we manyly bolmagy. Başgada birnäçe tapawutlar
            bar, emma esasy zat köpräk sözlük öwrenmeli. Köp söz tanasaňyz
            gepleşigiň özi ýuwaş ýuwaşdan owadanlaşar we manylaşar. Biziň
            peydalan.com.tm web saýtymyza çynlakaý gatnaşyň! Biz web saýtymyzy
            başardygymyzdan öwrenijä ýadawsyz we netijeli bolar ýaly
            kodlaşdyrýarys. Geljekde okyjylarymyzyň teklipleri bilen pikir
            alyşyp kodlarmyzy kämilleşdireris diýip umyt edýäris. Удачи!
          </p>
        </div>
      )}
    </div>
  );
}

export function Text(props) {
  const [texts, setTexts] = React.useState([]);
  const [lesson, setLesson] = React.useState({});

  React.useEffect(() => {
    axios
      .get("https://peydalan.com.tm/api/lesson?id=" + props.lessonId)
      .then((response) => {
        setLesson(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.lessonId]);

  React.useEffect(() => {
    // make an API call using axios
    if (!lesson.id) {
      return;
    }
    axios
      .get("https://peydalan.com.tm/api/text?lesson=" + lesson.id)
      .then((response) => {
        // set the array state to the response data
        setTexts(response.data);
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  }, [lesson]);

  if (!props.lessonId) {
    return <Navigate to="/lesson/choose" />;
  }

  return (
    <div className="text-frame">
      {lesson && <h2>{lesson.name}</h2>}
      <div className="text-links">
        {lesson && lesson.word1 && lesson.link1 && (
          <a href={lesson.link1}>{lesson.word1}</a>
        )}
        {lesson && lesson.word2 && lesson.link2 && (
          <a href={lesson.link2}>{lesson.word2}</a>
        )}
        {lesson && lesson.word3 && lesson.link3 && (
          <a href={lesson.link3}>{lesson.word3}</a>
        )}
      </div>
      {texts.map((item) => (
        <TextCard key={item.id} item={item} />
      ))}
    </div>
  );
}
